angular.module("MainApp").controller("components.slider-animado", $scope => {
  
  $scope.slideActivo = 0;
  $scope.setSlideActivo = index => {
    if (index === $scope.slides.length) {
      $scope.slideActivo = 0;
    } else if (index < 0) {
      $scope.slideActivo = $scope.slides.length - 1;
    } else {
      $scope.slideActivo = index;
    }
  };
});
