angular
  .module("ProductosModule")
  .controller(
    "productos.show",
    ($scope, $routeParams, $http, carrito, $window, $rootScope) => {
      if ($routeParams.url) {
        $scope.producto = {};
        $scope.cantActual = 1;
        $scope.productosRelacionados = [];
        $scope.imagenActiva = 0;
        $scope.categoriasObject = {};
        $scope.showFormasEnvio = false;
        $scope.showPromocionesPago = false;
        $scope.config = {};

        $scope.toggleFormasEnvio = () => {
          $scope.showFormasEnvio = !$scope.showFormasEnvio;
        };
        $scope.togglePromocionesPago = () => {
          $scope.showPromocionesPago = !$scope.showPromocionesPago;
        };

        $http({
          method: "GET",
          url: "/api/configuration",
        }).then(
          ({ data }) => {
            $scope.config = data;
          },
          (error) => {
            console.error(error);
          }
        );

        $scope.categorias = [];
        $http({
          method: "GET",
          url: "/api/categorias",
        }).then(
          ({ data }) => {
            $scope.categorias = data;
            const catObj = arrToObj(data, "_id");
            Object.keys(catObj).map((k) => {
              if (catObj[k].subcategorias)
                catObj[k].subcategorias = arrToObj(
                  catObj[k].subcategorias,
                  "_id"
                );
              Object.keys(catObj[k].subcategorias).map((k1) => {
                if (catObj[k].subcategorias[k1].subcategorias)
                  catObj[k].subcategorias[k1].subcategorias = arrToObj(
                    catObj[k].subcategorias[k1].subcategorias,
                    "_id"
                  );
              });
            });
            $scope.categoriasObject = catObj;
          },
          (error) => {
            console.error(error);
          }
        );

        $http({
          method: "GET",
          url: `/api/productos/by_url/${$routeParams.url}`,
        }).then(
          ({ data, status }) => {
            if (data.deshabilitado) {
              $window.location.replace("/productos");
            } else {
              $scope.producto = data;
              $scope.getProductoCarrito();
              getRelated(data._id, data.categoria[data.categoria.length - 1]);

              fbq("track", "ViewContent", {
                // content_ids: `Visita la página 'PRODUCTO#${data._id}'`,
                content_ids: data.codigoInterno ? data.codigoInterno : data._id,
                content_type: "product",
              });
            }
          },
          (error) => {
            console.error(error);
            $window.location.replace("/productos");
          }
        );

        const getRelated = (id, categ) => {
          $http({
            method: "GET",
            url: `/api/productos/related/${id}/${categ}`,
          }).then(
            ({ data }) => {
              $scope.productosRelacionados = data.filter(
                (p) => !p.deshabilitado
              );
            },
            (error) => {
              console.error(error);
            }
          );
        };

        $scope.setImagenActiva = (index) => {
          $scope.imagenActiva = index;
        };

        // $scope.addCant = () => {
        //   $scope.cantActual++;
        // };
        // $scope.subsCant = () => {
        //   $scope.cantActual = $scope.cantActual > 0 ? $scope.cantActual - 1 : 0;
        // };

        $scope.addToCarrito = () => {
          carrito.addProducto($scope.producto, $scope.cantActual);
          $scope.cantActual = 0;
          $scope.getProductoCarrito();
          fbq("track", "AddToCart", {
            content_ids: `Añade al carrito 'PRODUCTO#${$scope.producto._id}'`,
          });
          if ($rootScope.user) {
            $window.location.href = "/checkout";
          } else {
            $rootScope.showCarrito = true;
          }
        };
        $scope.removeFromCarrito = () => {
          carrito.borrarProducto($scope.producto._id);
          $scope.cantActual = 1;
          $scope.getProductoCarrito();
        };

        $scope.getProductoCarrito = () => {
          $scope.prodCarrito = carrito.getProducto($scope.producto._id);
        };

        $rootScope.$on("removedElementFromCarrito", $scope.getProductoCarrito);
      } else {
        $window.location.href = "/";
      }
    }
  );
