angular
  .module("CheckoutModule", [])
  .controller(
    "checkout.index",
    (
      $scope,
      $rootScope,
      auth,
      $http,
      $window,
      $routeParams,
      carrito,
      $filter
    ) => {
      if ($rootScope.user) {
        /////////

        fbq("track", "ViewContent", {
          content_ids: `Visita la página 'CHECKOUT'`,
        });

        carrito.fixPrices();

        $scope.entregas = [];
        $scope.tiposEnvios = [];
        $scope.couser = {
          envio: "",
        };
        $scope.pagarcon = { val: "" };

        $scope.config = {
          mediosPago: {
            mercadopago: {
              enabled: false,
            },
            todopago: {
              enabled: false,
            },
          },
        };

        $scope.localProducts = Object.assign({}, $rootScope.carrito.productos);

        $http({
          method: "GET",
          url: "/api/configuration",
          headers: auth.headers,
        }).then(
          ({ data }) => {
            $scope.config = data;
          },
          (error) => {
            console.error(error);
          }
        );

        $http({
          method: "GET",
          url: `/api/usuarios/${$rootScope.user._id}`,
          headers: auth.headers,
        }).then(
          ({ data }) => {
            $scope.couser = data;
            $scope.couser.envio = "";
          },
          (error) => {
            console.error(error);
          }
        );

        $http({
          method: "GET",
          url: `/api/tipoenvios/`,
          headers: auth.headers,
        }).then(
          ({ data }) => {
            $scope.tiposEnvios = data;
          },
          (error) => {
            console.error(error);
          }
        );

        $scope.mplink = "";
        $scope.tplink = "";
        $scope.redirectLink = "";

        $scope.completarTransaccion = () => {
          $scope.sent = true;
          $http({
            method: "PUT",
            url: `/api/usuarios/${$rootScope.user._id}`,
            headers: auth.headers,
            data: {
              nombre: $scope.couser.nombre,
              apellido: $scope.couser.apellido,
              telefono: $scope.couser.telefono,
              direccion: $scope.couser.direccion,
            },
          }).then(
            ({ status }) => {
              if (status === 200) {
                $scope.comprar($rootScope.carrito.productos);
              }
            },
            (error) => {
              console.error(error);
              $scope.sent = false;
            }
          );
        };

        $scope.sentComprar = false;
        $scope.ocurrioError = false;

        let categorias = [];
        $http({
          method: "GET",
          url: "/api/categorias",
        }).then(
          ({ data }) => {
            categorias = data;
          },
          (error) => {
            console.error(error);
          }
        );

        const findCategoria = (categArray) => {
          let out = false;
          if (categArray[0]) {
            let categoria;
            const categoria1 = categorias.find((c) => categArray[0] == c._id);
            if (categoria1) {
              categoria = categoria1;
              if (categArray[1]) {
                const categoria2 = categoria1.subcategorias.find(
                  (c) => categArray[1] == c._id
                );
                if (categoria2) {
                  categoria = categoria2;
                  if (categArray[2]) {
                    const categoria3 = categoria2.subcategorias.find(
                      (c) => categArray[2] == c._id
                    );
                    if (categoria3) {
                      categoria = categoria3;
                    }
                  }
                }
              }
            }
            if (categoria) {
              out = categoria.nombre;
            }
          }
          return out;
        };

        $scope.comprar = (productos) => {
          $scope.sentComprar = true;
          $scope.ocurrioError = false;
          $http({
            method: "POST",
            url: "/api/ordenventas",
            headers: auth.headers,
            data: {
              productos: Object.keys(productos).map((pid) => {
                let prod = productos[pid];
                return Object.assign(
                  {},
                  {
                    _id: pid,
                    nombre: prod.producto.nombre,
                    precio: prod.producto.descuento
                      ? prod.producto.precio -
                        prod.producto.precio * (prod.producto.descuento / 100)
                      : prod.producto.precio,
                    cantidad: prod.cant,
                    descripcion: prod.producto.descripcion,
                    imagenes: prod.producto.imagenes,
                  }
                );
              }),
              pagarcon: $scope.pagarcon.val,
              tipoEnvio: $scope.couser.envio,
              categoriasInteres: Object.keys(productos)
                .map((pid) => {
                  const prod = productos[pid];
                  const categArray = prod.producto.categoria;
                  return findCategoria(categArray);
                })
                .join(" - "),
            },
          }).then(
            ({ data }) => {
              if (data.ordenventa.botonPago) {
                if ($scope.pagarcon.val === "mercadopago") {
                  $scope.mplink = data.preference.body.init_point;
                  // $scope.mplink = data.preference.body.sandbox_init_point;
                } else {
                  $scope.tplink = data.ordenventa.botonPago;
                }
                $scope.redirectLink = `/compras/${data.ordenventa._id}`;
                $scope.sentComprar = false;
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                // fbq("track", "Purchase", {
                //   content_ids: `Usuario realiza una compra`,
                //   currency: "ARS",
                //   value: Object.keys($rootScope.carrito.productos)
                //     .map(
                //       (k) =>
                //         $rootScope.carrito.productos[k].producto.precio *
                //         $rootScope.carrito.productos[k].cant
                //     )
                //     .reduce((sum, p) => sum + p, 0),
                // });
                Object.keys(productos).map((pid) => {
                  carrito.borrarProducto(pid);
                });
                $scope.sent = false;
              } else {
                $scope.sentComprar = false;
                $scope.ocurrioError = true;
              }
            },
            (error) => {
              $scope.ocurrioError = true;
              $scope.sentComprar = false;
              $scope.sent = false;
              console.error(error);
            }
          );
        };

        $scope.continueToMP = () => {
          if ($scope.mplink) {
            // $window.location.replace($scope.redirectLink);
            $window.location.replace($scope.mplink);
            // $window.open($scope.mplink);
          }
        };

        $scope.continueToTP = () => {
          if ($scope.tplink) {
            $window.location.replace($scope.tplink);
          }
        };
      }
    }
  );
