angular.module("MainApp").factory("storage", ($window, $rootScope) => {
  return {
    getValue: key => {
      if (window.localStorage[`@lagalerianeuquen:${key}`]) {
        if (
          JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
            .type === "object"
        ) {
          return JSON.parse(
            JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
              .value
          );
        } else if (
          JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
            .type === "number"
        ) {
          return window.parseInt(
            JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
              .value
          );
        } else if (
          JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
            .type === "boolean"
        ) {
          return (
            JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
              .value === "true"
          );
        }
        return JSON.parse(window.localStorage[`@lagalerianeuquen:${key}`])
          .value;
      }
      return false;
    },
    saveValue: (key, value) => {
      const type = typeof value;
      const val = type === "object" ? JSON.stringify(value) : `${value}`;
      window.localStorage[`@lagalerianeuquen:${key}`] = JSON.stringify({
        type,
        value: val
      });
    },
    removeValue: key => {
      delete window.localStorage[`@lagalerianeuquen:${key}`];
    }
  };
});
