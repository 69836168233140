angular
  .module("ProductosModule", [])
  .controller(
    "productos.index",
    ($http, $scope, $rootScope, $timeout, $routeParams, $route, $location) => {
      fbq("track", "ViewContent", {
        content_ids: `Visita la página 'PRODUCTOS#INDEX'`
      });

      let productos = { items: [] };
      $scope.filtros = {};
      $scope.productosVisibles = [];
      $scope.cantProductos = 9;
      $scope.pagina = 1;
      $scope.paginas = 0;
      $scope.arrPaginas = [];
      $scope.categoriaActivo = [];
      $scope.offsetProductos = 0;
      $scope.loadedProductos = false;
      // $scope.filtroPrecio = null;
      // $scope.minPrecio = null;
      // $scope.maxPrecio = null;

      // FILTRADO POR PARAMS

      let addQueryParam = (key, value) => {
        $routeParams[key] = value;
        updateQueryString();
      };
      let removeQueryParam = key => {
        delete $routeParams[key];
        updateQueryString();
      };
      let updateQueryString = () => {
        $location.search($routeParams);
      };

      $timeout(() => {
        if ($routeParams.start) {
          setOffsetProducto(parseInt($routeParams.start));
        }
      }, 100);

      $scope.$on("$routeUpdate", () => {
        if (Object.keys($routeParams).length === 0) {
          $scope.borrarFiltros();
        }
      });

      // FILTRADO POR PARAMS

      $scope.categorias = [];
      $http({
        method: "GET",
        url: "/api/categorias"
      }).then(
        ({ data }) => {
          $scope.categorias = data;
          if ($routeParams.categoria) {
            $scope.setCategoria($routeParams.categoria);
          }
        },
        error => {
          console.error(error);
        }
      );

      $http({
        method: "GET",
        url: "/api/productos"
      }).then(
        ({ data }) => {
          productos.items = data.filter(p => !p.deshabilitado);
          productos.setVisibles = () => {
            $scope.arrPaginas = [];
            $scope.productosVisibles = productos.items.filter(producto =>
              Object.keys($scope.filtros).every(f =>
                $scope.filtros[f].fun(producto)
              )
            );
            $scope.paginas = Math.ceil(
              $scope.productosVisibles.length / $scope.cantProductos
            );
            for (let i = 0; i < $scope.paginas; i++) {
              $scope.arrPaginas.push(i + 1);
            }
          };
          productos.setVisibles();
          $scope.loadedProductos = true;
          // const sortPrecio = data.sort((p1, p2) => p1.precio >= p2.precio);
          // $scope.minPrecio = sortPrecio[0].precio;
          // $scope.maxPrecio = sortPrecio[sortPrecio.length - 1].precio;
          // $scope.filtroPrecio = $scope.maxPrecio;
        },
        error => {
          console.error(error);
        }
      );

      $scope.hayFiltros = () => Object.keys($scope.filtros).length > 0;

      $scope.borrarFiltros = () => {
        $scope.filtros = {};
        $scope.categoriaActivo = {};
        // $scope.filtroPrecio = $scope.maxPrecio;
        // delete $scope.filtros.precio;
        setOffsetProducto(0);
        productos.setVisibles();
        $routeParams = {};
        updateQueryString();
      };

      const setCategoriaActivo = str => {
        const catAct = [];
        const arr = str.split("__");
        const c1 = $scope.categorias.find(c => c.nombre === arr[0]);
        catAct.push(c1._id);
        if (arr.length >= 2 && c1.subcategorias) {
          const s1 = c1.subcategorias.find(s => s.nombre === arr[1]);
          catAct.push(s1._id);
          if (arr.length == 3 && s1.subcategorias) {
            const s2 = s1.subcategorias.find(s => s.nombre === arr[2]);
            catAct.push(s2._id);
          }
        }
        return catAct;
      };

      $scope.setCategoria = val => {
        const catAct = setCategoriaActivo(val);
        if (JSON.stringify($scope.categoriaActivo) !== JSON.stringify(catAct)) {
          $scope.loadedProductos && setOffsetProducto(0);
          $scope.categoriaActivo = catAct;
          $scope.filtros.categorias = {
            fun: producto =>
              producto.categoria.indexOf(
                $scope.categoriaActivo[$scope.categoriaActivo.length - 1]
              ) >= 0
          };
          addQueryParam("categoria", val);
        } else {
          $scope.categoriaActivo = {};
          delete $scope.filtros.categorias;
          removeQueryParam("categoria");
        }
        if (typeof productos.setVisibles === "function")
          productos.setVisibles();
      };

      // $scope.setRangoPrecio = () => {
      //   if (
      //     $scope.filtroPrecio &&
      //     $scope.minPrecio &&
      //     $scope.maxPrecio &&
      //     $scope.filtroPrecio >= $scope.minPrecio
      //   ) {
      //     if ($scope.filtroPrecio > $scope.minPrecio) {
      //       $scope.loadedProductos && $scope.setPagina(0);
      //       $scope.filtros.precio = {
      //         fun: producto =>
      //           producto.precio &&
      //           producto.precio >= $scope.minPrecio &&
      //           producto.precio <= $scope.filtroPrecio
      //       };
      //     } else {
      //       $scope.filtroPrecio = $scope.maxPrecio;
      //       delete $scope.filtros.precio;
      //     }
      //     if (typeof productos.setVisibles === "function")
      //       productos.setVisibles();
      //   }
      // };

      // let $scope.setPagina = index => {
      //   $scope.offsetProductos = parseInt(index);
      //   addQueryParam("start", $scope.offsetProductos);
      //   const pagActual =
      //     parseInt(Math.ceil($scope.offsetProductos / $scope.cantProductos)) +
      //     1;
      //   $scope.paginaActual = pagActual > 0 ? pagActual : 1;
      // };
      //
      // $scope.setOffsetProducto = nro => {
      //   $scope.setPagina(nro);
      // };
      //
      // $scope.endOffset = () => {
      //   let lastPage = Math.floor(
      //     $scope.productosVisibles.length / $scope.cantProductos
      //   );
      //   $scope.setPagina(lastPage * $scope.cantProductos);
      // };
      //
      // $scope.minusOffsetProducto = () => {
      //   if ($scope.offsetProductos - $scope.cantProductos >= 0)
      //     $scope.setPagina($scope.offsetProductos - $scope.cantProductos);
      //   else {
      //     $scope.setPagina(0);
      //     removeQueryParam("start");
      //   }
      // };
      // $scope.plusOffsetProducto = () => {
      //   if (
      //     $scope.offsetProductos + $scope.cantProductos <
      //     $scope.productosVisibles.length
      //   )
      //     $scope.setPagina($scope.offsetProductos + $scope.cantProductos);
      //   else $scope.setPagina($scope.offsetProductos);
      // };

      const setOffsetProducto = offset => {
        $scope.pagina = offset / $scope.cantProductos + 1;
        $scope.offsetProductos = offset;
        addQueryParam("start", $scope.offsetProductos);
      };

      $scope.menosPagina = () => {
        if ($scope.pagina > 1) {
          $scope.pagina -= 1;
          $scope.offsetProductos -= $scope.cantProductos;
          addQueryParam("start", $scope.offsetProductos);
        }
      };
      $scope.setPagina = pagina => {
        $scope.pagina = pagina + 1;
        $scope.offsetProductos = pagina * $scope.cantProductos;
        addQueryParam("start", $scope.offsetProductos);
      };
      $scope.masPagina = () => {
        if ($scope.pagina < $scope.paginas) {
          $scope.pagina += 1;
          $scope.offsetProductos += $scope.cantProductos;
          addQueryParam("start", $scope.offsetProductos);
        }
      };
    }
  );
