"use strict";

angular.module("MainApp", ["ngRoute", "ngTouch", "HomeModule", "ProductosModule", // "CarritoModule"
"CheckoutModule", "ComprasModule", "angular-google-analytics"]).config(function (AnalyticsProvider) {
  AnalyticsProvider.setAccount("UA-147115981-1");
}).controller("mainController", function ($location, $rootScope, $window, carrito, auth, $http, Analytics) {
  $rootScope.user = auth.user;
  $rootScope.skInputFocus = false;
  carrito.start();
  $rootScope.isMobile = $window.innerWidth <= 768;
  var originalWindowHeight = $window.innerHeight;
  angular.element($window).bind("resize", function () {
    $rootScope.isMobile = $window.innerWidth <= 768;

    if ($window.innerHeight > 350) {
      $rootScope.skInputFocus = false;
    } else {
      $rootScope.skInputFocus = true;
    }

    $rootScope.$$phase || $rootScope.$apply();
  });
  $rootScope.$on("$routeChangeSuccess", function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    var bodyClass = "";
    Analytics.trackPage($location.$$path);
    var path = $location.$$path.split("/");
    path.map(function (tramo) {
      if (tramo !== "") {
        bodyClass += "-".concat(tramo);
      }
    });
    $rootScope.bodyClass = bodyClass !== "" ? "page".concat(bodyClass) : "page-home";
    $rootScope.menuOpened = false;

    $rootScope.toggleMenu = function () {
      $rootScope.menuOpened = $rootScope.menuOpened ? false : true;
      $rootScope.showCarrito = false;
    };

    $rootScope.closeMenu = function () {
      $rootScope.menuOpened = false;
    };
  });
  $rootScope.proximamenteDismissed = false;
  $rootScope.config = {};
  $http.get("/api/configuration").then(function (_ref) {
    var data = _ref.data;
    $rootScope.config = data;
  }, function (error) {
    console.error(error);
  });
  $rootScope.showCarrito = false;

  $rootScope.toggleCarrito = function (val) {
    $rootScope.showCarrito = val;
    $rootScope.menuOpened = false;
    $rootScope.$$phase || $rootScope.$apply();
  };
});
"use strict";

angular.module("MainApp").directive("mainMenu", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/main-menu.html",
    controller: "general.main-menu"
  };
}).directive("footerSection", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/footer-section.html",
    controller: "general.footer"
  };
}).directive("proximamente", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/proximamente.html",
    controller: "general.proximamente"
  };
}).directive("mantenimiento", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/mantenimiento.html"
  };
}).directive("tarjetaProducto", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/tarjeta-producto.html",
    scope: {
      producto: "=",
      config: "="
    }
  };
}).directive("loadFrame", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $("document").ready(function () {
        $(elm).attr("src", attrs.source);
      });
    }
  };
}).directive("scrollRight", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "+=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollLeft", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "-=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollToContacto", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $("footer-section").offset().top
        }, 500);
      });
    }
  };
}).directive("scrollToTarget", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $(attrs.target).offset().top
        }, 700);
      });
    }
  };
}).directive("escondeMenu", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("#navbar").collapse("hide");
      });
    }
  };
}).directive("flotante", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(window).on("scroll", function (e) {
        if ($(window).scrollTop() > 67) {
          $(elm).addClass("flotante");
        } else {
          $(elm).removeClass("flotante");
        }
      });
    }
  };
}).directive("colorSvg", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      elm[0].addEventListener("load", function () {
        var paths = this.getSVGDocument().querySelectorAll("path");

        for (var i = 0; i < paths.length; i++) {
          paths[i].setAttribute("style", "fill: ".concat(attrs.colorSvg));
        }
      });
    }
  };
}).directive("scrollTargetDown", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      var mouseOver = false;
      elm[0].addEventListener("mouseenter", function () {
        mouseOver = true;
        scrollTarget();
      });
      elm[0].addEventListener("mouseleave", function () {
        mouseOver = false;
        stopScroll();
      });

      var stopScroll = function stopScroll() {
        $(attrs.scrollTargetDown).stop();
      };

      var scrollTarget = function scrollTarget() {
        if (mouseOver) {
          $(attrs.scrollTargetDown).stop().animate({
            scrollTop: "+=50px"
          }, 400, "linear", function () {
            scrollTarget();
          });
        }
      };
    }
  };
}).directive("scrollTargetUp", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      var mouseOver = false;
      elm[0].addEventListener("mouseenter", function () {
        mouseOver = true;
        scrollTarget();
      });
      elm[0].addEventListener("mouseleave", function () {
        mouseOver = false;
        stopScroll();
      });

      var stopScroll = function stopScroll() {
        $(attrs.scrollTargetUp).stop();
      };

      var scrollTarget = function scrollTarget() {
        if (mouseOver) {
          $(attrs.scrollTargetUp).stop().animate({
            scrollTop: "-=50px"
          }, 400, "linear", function () {
            scrollTarget();
          });
        }
      };
    }
  };
});
"use strict";

angular.module("MainApp").config(function ($httpProvider, $routeProvider, $locationProvider) {
  $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  $locationProvider.html5Mode(true);
  $routeProvider.when("/", {
    templateUrl: "/views/home/index.html",
    controller: "home.index"
  }).when("/nosotros", {
    templateUrl: "/views/nosotros/index.html",
    controller: function controller() {
      fbq("track", "ViewContent", {
        content_ids: "Visita la p\xE1gina 'NOSOTROS'"
      });
    }
  }) ///////////
  .when("/spfl", {
    templateUrl: "/views/usuarios/set-pass-first-load.html",
    controller: "usuarios.set-pass-first-load"
  }).when("/logout", {
    template: "",
    controller: function controller(auth, $window) {
      auth.logout();
      $window.location.replace("/");
    }
  }) ///////////
  .otherwise({
    templateUrl: "/views/home/index.html",
    redirectTo: "/"
  });
});
"use strict";

angular.module("MainApp").config(function ($routeProvider, $locationProvider) {
  $routeProvider.when("/productos", {
    templateUrl: "/views/productos/index.html",
    controller: "productos.index",
    reloadOnSearch: false
  }).when("/productos/:url", {
    templateUrl: "/views/productos/show.html",
    controller: "productos.show"
  }).when("/checkout", {
    templateUrl: "views/checkout/index.html",
    controller: "checkout.index",
    resolve: {
      secureAccess: function secureAccess($rootScope, $window) {
        if (!$rootScope.user) {
          $window.location.replace("/");
        }
      }
    }
  }).when("/compras", {
    templateUrl: "/views/compras/index.html",
    controller: "compras.index",
    resolve: {
      secureAccess: function secureAccess($rootScope, $window) {
        if (!$rootScope.user) {
          $window.location.replace("/");
        }
      }
    }
  }).when("/compras/confirm-payment/:id", {
    templateUrl: "/views/compras/confirm-payment.html",
    controller: "compras.confirm-payment",
    resolve: {
      secureAccess: function secureAccess($rootScope, $window) {
        if (!$rootScope.user) {
          $window.location.replace("/");
        }
      }
    }
  }).when("/compras/:id", {
    templateUrl: "/views/compras/show.html",
    controller: "compras.show",
    resolve: {
      secureAccess: function secureAccess($rootScope, $window) {
        if (!$rootScope.user) {
          $window.location.replace("/");
        }
      }
    }
  });
});
"use strict";

angular.module("CheckoutModule", []).controller("checkout.index", function ($scope, $rootScope, auth, $http, $window, $routeParams, carrito, $filter) {
  if ($rootScope.user) {
    /////////
    fbq("track", "ViewContent", {
      content_ids: "Visita la p\xE1gina 'CHECKOUT'"
    });
    carrito.fixPrices();
    $scope.entregas = [];
    $scope.tiposEnvios = [];
    $scope.couser = {
      envio: ""
    };
    $scope.pagarcon = {
      val: ""
    };
    $scope.config = {
      mediosPago: {
        mercadopago: {
          enabled: false
        },
        todopago: {
          enabled: false
        }
      }
    };
    $scope.localProducts = Object.assign({}, $rootScope.carrito.productos);
    $http({
      method: "GET",
      url: "/api/configuration",
      headers: auth.headers
    }).then(function (_ref) {
      var data = _ref.data;
      $scope.config = data;
    }, function (error) {
      console.error(error);
    });
    $http({
      method: "GET",
      url: "/api/usuarios/".concat($rootScope.user._id),
      headers: auth.headers
    }).then(function (_ref2) {
      var data = _ref2.data;
      $scope.couser = data;
      $scope.couser.envio = "";
    }, function (error) {
      console.error(error);
    });
    $http({
      method: "GET",
      url: "/api/tipoenvios/",
      headers: auth.headers
    }).then(function (_ref3) {
      var data = _ref3.data;
      $scope.tiposEnvios = data;
    }, function (error) {
      console.error(error);
    });
    $scope.mplink = "";
    $scope.tplink = "";
    $scope.redirectLink = "";

    $scope.completarTransaccion = function () {
      $scope.sent = true;
      $http({
        method: "PUT",
        url: "/api/usuarios/".concat($rootScope.user._id),
        headers: auth.headers,
        data: {
          nombre: $scope.couser.nombre,
          apellido: $scope.couser.apellido,
          telefono: $scope.couser.telefono,
          direccion: $scope.couser.direccion
        }
      }).then(function (_ref4) {
        var status = _ref4.status;

        if (status === 200) {
          $scope.comprar($rootScope.carrito.productos);
        }
      }, function (error) {
        console.error(error);
        $scope.sent = false;
      });
    };

    $scope.sentComprar = false;
    $scope.ocurrioError = false;
    var categorias = [];
    $http({
      method: "GET",
      url: "/api/categorias"
    }).then(function (_ref5) {
      var data = _ref5.data;
      categorias = data;
    }, function (error) {
      console.error(error);
    });

    var findCategoria = function findCategoria(categArray) {
      var out = false;

      if (categArray[0]) {
        var categoria;
        var categoria1 = categorias.find(function (c) {
          return categArray[0] == c._id;
        });

        if (categoria1) {
          categoria = categoria1;

          if (categArray[1]) {
            var categoria2 = categoria1.subcategorias.find(function (c) {
              return categArray[1] == c._id;
            });

            if (categoria2) {
              categoria = categoria2;

              if (categArray[2]) {
                var categoria3 = categoria2.subcategorias.find(function (c) {
                  return categArray[2] == c._id;
                });

                if (categoria3) {
                  categoria = categoria3;
                }
              }
            }
          }
        }

        if (categoria) {
          out = categoria.nombre;
        }
      }

      return out;
    };

    $scope.comprar = function (productos) {
      $scope.sentComprar = true;
      $scope.ocurrioError = false;
      $http({
        method: "POST",
        url: "/api/ordenventas",
        headers: auth.headers,
        data: {
          productos: Object.keys(productos).map(function (pid) {
            var prod = productos[pid];
            return Object.assign({}, {
              _id: pid,
              nombre: prod.producto.nombre,
              precio: prod.producto.descuento ? prod.producto.precio - prod.producto.precio * (prod.producto.descuento / 100) : prod.producto.precio,
              cantidad: prod.cant,
              descripcion: prod.producto.descripcion,
              imagenes: prod.producto.imagenes
            });
          }),
          pagarcon: $scope.pagarcon.val,
          tipoEnvio: $scope.couser.envio,
          categoriasInteres: Object.keys(productos).map(function (pid) {
            var prod = productos[pid];
            var categArray = prod.producto.categoria;
            return findCategoria(categArray);
          }).join(" - ")
        }
      }).then(function (_ref6) {
        var data = _ref6.data;

        if (data.ordenventa.botonPago) {
          if ($scope.pagarcon.val === "mercadopago") {
            $scope.mplink = data.preference.body.init_point; // $scope.mplink = data.preference.body.sandbox_init_point;
          } else {
            $scope.tplink = data.ordenventa.botonPago;
          }

          $scope.redirectLink = "/compras/".concat(data.ordenventa._id);
          $scope.sentComprar = false;
          document.body.scrollTop = document.documentElement.scrollTop = 0; // fbq("track", "Purchase", {
          //   content_ids: `Usuario realiza una compra`,
          //   currency: "ARS",
          //   value: Object.keys($rootScope.carrito.productos)
          //     .map(
          //       (k) =>
          //         $rootScope.carrito.productos[k].producto.precio *
          //         $rootScope.carrito.productos[k].cant
          //     )
          //     .reduce((sum, p) => sum + p, 0),
          // });

          Object.keys(productos).map(function (pid) {
            carrito.borrarProducto(pid);
          });
          $scope.sent = false;
        } else {
          $scope.sentComprar = false;
          $scope.ocurrioError = true;
        }
      }, function (error) {
        $scope.ocurrioError = true;
        $scope.sentComprar = false;
        $scope.sent = false;
        console.error(error);
      });
    };

    $scope.continueToMP = function () {
      if ($scope.mplink) {
        // $window.location.replace($scope.redirectLink);
        $window.location.replace($scope.mplink); // $window.open($scope.mplink);
      }
    };

    $scope.continueToTP = function () {
      if ($scope.tplink) {
        $window.location.replace($scope.tplink);
      }
    };
  }
});
"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

angular.module("CarritoModule", []).controller("carrito.index", function ($scope, $rootScope, $http, $filter) {
  $scope.showPresupuesto = false;
  $scope.cantTotal = 0;

  $scope.muestraFormPresupuesto = function () {
    $scope.showPresupuesto = true;
  };

  $scope.escondeFormPresupuesto = function () {
    $scope.showPresupuesto = false;
  };

  $scope.solic = {
    nombre: "",
    empresa: "",
    email: ""
  };
  $scope.presupuesto = {
    estado: 0,
    enviado: false,
    mensaje: ""
  };
  $scope.sent = false;

  $scope.presupuestar = function (payerInfo) {
    var soloCliente = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    $scope.sent = true;

    if (Object.keys($scope.carrito.productos).length > 0) {
      $http.post("/api/mailing/presupuesto", {
        solic: $scope.solic,
        productos: $scope.carrito.productos,
        total: $scope.total,
        soloCliente: soloCliente
      }).then(function (response) {
        if (response.status === 200) {
          $scope.presupuesto.estado = response.status;
          $scope.presupuesto.mensaje = "Presupuesto enviado correctamente";
          $scope.presupuesto.enviado = true;
        }

        $scope.sent = false;
      }, function (error) {
        $scope.sent = false;
        $scope.presupuesto.estado = 200;
        $scope.presupuesto.mensaje = "Ocurrió un problema al enviar el presupuesto, por favor, verifica tus datos e inténtalo nuevamente";
        $scope.presupuesto.enviado = true;
        console.error(error);
      });
    }
  };

  $scope.imprimePresupuesto = function () {
    var script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.37/pdfmake.min.js";
    document.body.appendChild(script);

    script.onload = function () {
      var script2 = document.createElement("script");
      script2.src = "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.37/vfs_fonts.js";
      document.body.appendChild(script2);

      script2.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = 2226;
        canvas.height = 406;
        var context = canvas.getContext("2d");
        var base_image = new Image();

        base_image.onload = function () {
          context.drawImage(base_image, 0, 0);
          var outPDF = {
            content: [{
              image: "logo",
              width: 515
            }, {
              text: "\n\n"
            }, {
              table: {
                margin: 30,
                headerRows: 1,
                widths: ["*", "*", "*"],
                body: [["Producto", "Precio x Cantidad", "Precio Total"]]
              }
            }, {
              text: "\n\n"
            }, {
              text: "Los presupuestos son enviados a ventas@lagalerianeuquen.com"
            }],
            images: {
              logo: canvas.toDataURL("image/jpeg")
            }
          };

          var _arr = Object.entries($rootScope.carrito.productos);

          for (var _i = 0; _i < _arr.length; _i++) {
            var _arr$_i = _slicedToArray(_arr[_i], 2),
                pid = _arr$_i[0],
                producto = _arr$_i[1];

            if (producto.cant > 0) {
              outPDF.content[2].table.body.push([{
                text: producto.producto.nombre,
                bold: true
              }, {
                text: "$ ".concat(producto.producto.precio, " x ").concat(producto.cant, " unidades")
              }, {
                text: "$ ".concat(producto.producto.precio * producto.cant)
              }]);
            }
          }

          outPDF.content[2].table.body.push(["", "", {
            text: "Total: $ ".concat($filter("precioTotal")($rootScope.carrito.productos).toFixed(2)),
            bold: true,
            fontSize: 13
          }]);
          pdfMake.createPdf(outPDF).download("Presupuesto lagalerianeuquen ".concat(new Date().getDate() + "-" + new Date().getMonth(), ".pdf"));
        };

        base_image.src = "/assets/images/logo-pdf.jpg";
      };
    };
  };
}).filter("cantidadProductos", function () {
  return function (productos) {
    return productos && Object.keys(productos).length > 0 ? Object.keys(productos).reduce(function (acumulador, pid) {
      acumulador += productos[pid].cant;
      return acumulador;
    }, 0) : 0;
  };
}).filter("precioTotal", function () {
  return function (productos) {
    return productos && Object.keys(productos).length > 0 ? Object.keys(productos).reduce(function (acumulador, pid) {
      acumulador += productos[pid].cant * productos[pid].producto.precio;
      return acumulador;
    }, 0) : 0;
  };
});
"use strict";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module("ComprasModule", []).controller("compras.index", function ($scope, auth, $http, $routeParams, $location) {
  fbq("track", "ViewContent", {
    content_ids: "Visita la p\xE1gina 'COMPRAS#INDEX'"
  });
  $scope.compras = [];
  $scope.flash = "";

  var verifyStatus = function verifyStatus(d) {
    if (d) {
      if (d.botonPago) {
        if (d.botonPago.indexOf("mercadopago") >= 0) {
          if (!!d.paymentInfo) {
            return true; // "PAGADO";
          } else {
            return false; // "NO CONCRETADO";
          }
        } else {
          if (!!d.paymentInfo) {
            if (d.paymentInfo.Payload) {
              if (d.paymentInfo.StatusCode === -1) {
                return true; // "PAGADO";
              } else {
                return false; // "RECHAZADO/FALLIDO";
              }
            } else {
              return false; // "NO CONCRETADO";
            }
          }
        }
      }
    }

    return false; // "";
  };

  $http({
    method: "GET",
    url: "/api/ordenventas/my",
    headers: auth.headers
  }).then(function (_ref) {
    var data = _ref.data;
    $scope.compras = data.map(function (c) {
      return _objectSpread({}, c, {
        pagoRealizado: verifyStatus(c)
      });
    });
    console.log($scope.compras);
  }, function (error) {
    console.error(error);
  });

  if ($routeParams.flash) {
    $scope.flash = $routeParams.flash;
  }

  $scope.removeFlash = function () {
    $scope.flash = "";
    $location.search("flash", null);
  };
});
"use strict";

angular.module("HomeModule", []).controller("home.index", function ($scope, $http, $timeout) {
  fbq("track", "ViewContent", {
    content_ids: "Visita la p\xE1gina 'HOME'"
  });
  $scope.slides = [];
  $scope.error = false;
  $http.get("/api/sliders").then(function (_ref) {
    var data = _ref.data;
    $scope.slides = data;
  }, function (error) {
    console.error(error);
    $scope.error = "Error al cargar los slides, por favor recargue la página";
  });
  $scope.productosDestacados = [];
  $http({
    method: "GET",
    url: "/api/productos/destacados"
  }).then(function (_ref2) {
    var data = _ref2.data;
    $scope.productosDestacados = data;
  }, function (error) {
    console.error(error);
  });
  $scope.categoriaSelected = "";
  $scope.categorias = [];
  $http({
    method: "GET",
    url: "/api/categorias"
  }).then(function (_ref3) {
    var data = _ref3.data;
    $scope.categorias = data;
    $scope.categoriaSelected = data[0]._id;
  }, function (error) {
    console.error(error);
  });

  $scope.setCategoriaSelected = function (id) {
    $scope.categoriaSelected = id;
  };

  $scope.promocion = {};
  $http({
    method: "GET",
    url: "/api/promocions/next"
  }).then(function (_ref4) {
    var data = _ref4.data;
    $scope.promocion = data;
    countDown();
  }, function (error) {
    console.error(error);
  });

  var countDown = function countDown() {
    var resta = new Date($scope.promocion.fechaFin) - new Date();

    if (resta > 0) {
      $scope.restaDias = Math.floor(resta / (1000 * 60 * 60 * 24));
      $scope.restaHoras = Math.floor(resta % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      $scope.restaMinutos = Math.floor(resta % (1000 * 60 * 60) / (1000 * 60));
      $scope.restaSegundos = Math.floor(resta % (1000 * 60) / 1000);
      $timeout(function () {
        countDown();
      }, 1000);
    } else {
      $scope.promocionVencida = true;
    }
  };
});
"use strict";

angular.module("ProductosModule", []).controller("productos.index", function ($http, $scope, $rootScope, $timeout, $routeParams, $route, $location) {
  fbq("track", "ViewContent", {
    content_ids: "Visita la p\xE1gina 'PRODUCTOS#INDEX'"
  });
  var productos = {
    items: []
  };
  $scope.filtros = {};
  $scope.productosVisibles = [];
  $scope.cantProductos = 9;
  $scope.pagina = 1;
  $scope.paginas = 0;
  $scope.arrPaginas = [];
  $scope.categoriaActivo = [];
  $scope.offsetProductos = 0;
  $scope.loadedProductos = false; // $scope.filtroPrecio = null;
  // $scope.minPrecio = null;
  // $scope.maxPrecio = null;
  // FILTRADO POR PARAMS

  var addQueryParam = function addQueryParam(key, value) {
    $routeParams[key] = value;
    updateQueryString();
  };

  var removeQueryParam = function removeQueryParam(key) {
    delete $routeParams[key];
    updateQueryString();
  };

  var updateQueryString = function updateQueryString() {
    $location.search($routeParams);
  };

  $timeout(function () {
    if ($routeParams.start) {
      setOffsetProducto(parseInt($routeParams.start));
    }
  }, 100);
  $scope.$on("$routeUpdate", function () {
    if (Object.keys($routeParams).length === 0) {
      $scope.borrarFiltros();
    }
  }); // FILTRADO POR PARAMS

  $scope.categorias = [];
  $http({
    method: "GET",
    url: "/api/categorias"
  }).then(function (_ref) {
    var data = _ref.data;
    $scope.categorias = data;

    if ($routeParams.categoria) {
      $scope.setCategoria($routeParams.categoria);
    }
  }, function (error) {
    console.error(error);
  });
  $http({
    method: "GET",
    url: "/api/productos"
  }).then(function (_ref2) {
    var data = _ref2.data;
    productos.items = data.filter(function (p) {
      return !p.deshabilitado;
    });

    productos.setVisibles = function () {
      $scope.arrPaginas = [];
      $scope.productosVisibles = productos.items.filter(function (producto) {
        return Object.keys($scope.filtros).every(function (f) {
          return $scope.filtros[f].fun(producto);
        });
      });
      $scope.paginas = Math.ceil($scope.productosVisibles.length / $scope.cantProductos);

      for (var i = 0; i < $scope.paginas; i++) {
        $scope.arrPaginas.push(i + 1);
      }
    };

    productos.setVisibles();
    $scope.loadedProductos = true; // const sortPrecio = data.sort((p1, p2) => p1.precio >= p2.precio);
    // $scope.minPrecio = sortPrecio[0].precio;
    // $scope.maxPrecio = sortPrecio[sortPrecio.length - 1].precio;
    // $scope.filtroPrecio = $scope.maxPrecio;
  }, function (error) {
    console.error(error);
  });

  $scope.hayFiltros = function () {
    return Object.keys($scope.filtros).length > 0;
  };

  $scope.borrarFiltros = function () {
    $scope.filtros = {};
    $scope.categoriaActivo = {}; // $scope.filtroPrecio = $scope.maxPrecio;
    // delete $scope.filtros.precio;

    setOffsetProducto(0);
    productos.setVisibles();
    $routeParams = {};
    updateQueryString();
  };

  var setCategoriaActivo = function setCategoriaActivo(str) {
    var catAct = [];
    var arr = str.split("__");
    var c1 = $scope.categorias.find(function (c) {
      return c.nombre === arr[0];
    });
    catAct.push(c1._id);

    if (arr.length >= 2 && c1.subcategorias) {
      var s1 = c1.subcategorias.find(function (s) {
        return s.nombre === arr[1];
      });
      catAct.push(s1._id);

      if (arr.length == 3 && s1.subcategorias) {
        var s2 = s1.subcategorias.find(function (s) {
          return s.nombre === arr[2];
        });
        catAct.push(s2._id);
      }
    }

    return catAct;
  };

  $scope.setCategoria = function (val) {
    var catAct = setCategoriaActivo(val);

    if (JSON.stringify($scope.categoriaActivo) !== JSON.stringify(catAct)) {
      $scope.loadedProductos && setOffsetProducto(0);
      $scope.categoriaActivo = catAct;
      $scope.filtros.categorias = {
        fun: function fun(producto) {
          return producto.categoria.indexOf($scope.categoriaActivo[$scope.categoriaActivo.length - 1]) >= 0;
        }
      };
      addQueryParam("categoria", val);
    } else {
      $scope.categoriaActivo = {};
      delete $scope.filtros.categorias;
      removeQueryParam("categoria");
    }

    if (typeof productos.setVisibles === "function") productos.setVisibles();
  }; // $scope.setRangoPrecio = () => {
  //   if (
  //     $scope.filtroPrecio &&
  //     $scope.minPrecio &&
  //     $scope.maxPrecio &&
  //     $scope.filtroPrecio >= $scope.minPrecio
  //   ) {
  //     if ($scope.filtroPrecio > $scope.minPrecio) {
  //       $scope.loadedProductos && $scope.setPagina(0);
  //       $scope.filtros.precio = {
  //         fun: producto =>
  //           producto.precio &&
  //           producto.precio >= $scope.minPrecio &&
  //           producto.precio <= $scope.filtroPrecio
  //       };
  //     } else {
  //       $scope.filtroPrecio = $scope.maxPrecio;
  //       delete $scope.filtros.precio;
  //     }
  //     if (typeof productos.setVisibles === "function")
  //       productos.setVisibles();
  //   }
  // };
  // let $scope.setPagina = index => {
  //   $scope.offsetProductos = parseInt(index);
  //   addQueryParam("start", $scope.offsetProductos);
  //   const pagActual =
  //     parseInt(Math.ceil($scope.offsetProductos / $scope.cantProductos)) +
  //     1;
  //   $scope.paginaActual = pagActual > 0 ? pagActual : 1;
  // };
  //
  // $scope.setOffsetProducto = nro => {
  //   $scope.setPagina(nro);
  // };
  //
  // $scope.endOffset = () => {
  //   let lastPage = Math.floor(
  //     $scope.productosVisibles.length / $scope.cantProductos
  //   );
  //   $scope.setPagina(lastPage * $scope.cantProductos);
  // };
  //
  // $scope.minusOffsetProducto = () => {
  //   if ($scope.offsetProductos - $scope.cantProductos >= 0)
  //     $scope.setPagina($scope.offsetProductos - $scope.cantProductos);
  //   else {
  //     $scope.setPagina(0);
  //     removeQueryParam("start");
  //   }
  // };
  // $scope.plusOffsetProducto = () => {
  //   if (
  //     $scope.offsetProductos + $scope.cantProductos <
  //     $scope.productosVisibles.length
  //   )
  //     $scope.setPagina($scope.offsetProductos + $scope.cantProductos);
  //   else $scope.setPagina($scope.offsetProductos);
  // };


  var setOffsetProducto = function setOffsetProducto(offset) {
    $scope.pagina = offset / $scope.cantProductos + 1;
    $scope.offsetProductos = offset;
    addQueryParam("start", $scope.offsetProductos);
  };

  $scope.menosPagina = function () {
    if ($scope.pagina > 1) {
      $scope.pagina -= 1;
      $scope.offsetProductos -= $scope.cantProductos;
      addQueryParam("start", $scope.offsetProductos);
    }
  };

  $scope.setPagina = function (pagina) {
    $scope.pagina = pagina + 1;
    $scope.offsetProductos = pagina * $scope.cantProductos;
    addQueryParam("start", $scope.offsetProductos);
  };

  $scope.masPagina = function () {
    if ($scope.pagina < $scope.paginas) {
      $scope.pagina += 1;
      $scope.offsetProductos += $scope.cantProductos;
      addQueryParam("start", $scope.offsetProductos);
    }
  };
});
"use strict";

angular.module("CarritoModule").directive("filaCarrito", function () {
  return {
    restrict: "A",
    scope: {
      producto: "="
    },
    controller: "carrito.fila-carrito",
    templateUrl: "views/carrito/fila-carrito.html"
  };
});
"use strict";

angular.module("MainApp").directive("homeCabecera", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-cabecera.html"
  };
}).directive("homeCategorias", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-categorias.html"
  };
}).directive("homeDestacados", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-destacados.html"
  };
}).directive("homePromo", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-promo.html"
  };
});
"use strict";

var _this = void 0;

angular.module("ProductosModule").directive("listaCardProductos", function () {
  return {
    restrict: "E",
    scope: {
      productos: "=",
      offsetProductos: "=",
      cantProductos: "="
    },
    templateUrl: "/views/productos/lista-cards.html"
  };
}).directive("cardProducto", function () {
  return {
    restrict: "E",
    scope: {
      producto: "="
    },
    controller: "productos.producto",
    templateUrl: "/views/productos/card-producto.html"
  };
}).directive("expandeLista", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(_this).find("ul") // [TBM]
        .fadeIn(parseInt(attrs.time));
      });
    }
  };
}).directive("cierraTodos", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(".lista-categorias").hide(); // [TMB]
      });
    }
  };
}).directive("calcularAltura", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      var height = 0;
      height += elm.find("> .subcategoria-n1").length * 20;
      height += elm.find("> .contenido-subcategoria-n1 >.subcategoria-n2").length * 32; // scope.alturacalculada=`{height:'${height}px'}`;

      elm.height("".concat(height, "px"));
    }
  };
}).directive("reachingEnd", function ($timeout) {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $timeout(function () {
        if ($(window).scrollTop() + window.innerHeight >= $("footer-section").offset().top - 0) {
          $(elm).addClass("attached");
        } else {
          $(elm).removeClass("attached");
        }
      });
      $(window).on("scroll", function (e) {
        if ($(window).scrollTop() + window.innerHeight >= $("footer-section").offset().top - 0) {
          $(elm).addClass("attached");
        } else {
          $(elm).removeClass("attached");
        }
      });
    }
  };
});
"use strict";

angular.module("MainApp").filter("contarProductos", function () {
  return function (productos) {
    return productos ? Object.keys(productos).reduce(function (acumulador, pid) {
      acumulador += productos[pid].cant;
      return acumulador;
    }, 0) : "";
  };
}).filter("precio", function () {
  return function (input) {
    if (input) {
      return parseFloat(input).toFixed(2);
    }

    return "";
  };
}).filter("encodeurl", function () {
  return function (input) {
    if (input) {
      return window.encodeURIComponent(input);
    }

    return "";
  };
}).filter("removeTrailingSlash", function () {
  return function (input) {
    if (input) {
      if (input[0] === "/") return input.slice(1);else return input;
    }

    return "";
  };
}).filter("objLength", function () {
  return function (obj) {
    return obj ? Object.keys(obj).length : "";
  };
}).filter("urlifyProducto", function () {
  return function (producto) {
    return producto && producto.nombre && producto._id ? "".concat(producto.nombre.toLowerCase().replace(/\//g, "%2F").replace(/\s/g, "-").replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o").replace(/ú/g, "u").replace(/ñ/g, "n"), "-").concat(producto._id) : "";
  };
}).filter("precioTotalCarrito", function () {
  return function (productos) {
    return productos ? Object.keys(productos).reduce(function (acumulador, pid) {
      if (acumulador >= 0 && productos[pid].producto.precio && productos[pid].producto.precio > 0) {
        acumulador += (productos[pid].cant ? productos[pid].cant : productos[pid].cantidad ? productos[pid].cantidad : 0) * (productos[pid].producto.descuento ? productos[pid].producto.precio - productos[pid].producto.precio * (productos[pid].producto.descuento / 100) : productos[pid].producto.precio);
      } else {
        acumulador = -1;
      }

      return acumulador;
    }, 0) : "";
  };
}).filter("precioTotal", function () {
  return function (productos) {
    return productos ? Object.keys(productos).reduce(function (acumulador, pid) {
      if (acumulador >= 0 && productos[pid].precio && productos[pid].precio > 0) {
        acumulador += (productos[pid].cant ? productos[pid].cant : productos[pid].cantidad ? productos[pid].cantidad : 0) * productos[pid].precio;
      } else {
        acumulador = -1;
      }

      return acumulador;
    }, 0) : "";
  };
}).filter("padTwo", function () {
  return function (number) {
    return number <= 9 ? ("0" + number).slice(-2) : number;
  };
});
"use strict";

angular.module("MainApp").factory("auth", function ($http, $window) {
  var getToken = function getToken() {
    return $window.localStorage["@lagalerianeuquen-token"];
  };

  var saveToken = function saveToken(token) {
    $window.localStorage["@lagalerianeuquen-token"] = token;
  };

  var user = function user() {
    if (getToken() && JSON.parse($window.atob(getToken().split(".")[1])).exp > Date.now() / 1000) {
      var payload = JSON.parse($window.atob(getToken().split(".")[1]));
      return Object.assign({}, {
        _id: payload._id,
        email: payload.email,
        name: payload.name
      }, payload.role && {
        role: payload.role
      });
    } else {
      return false;
    }
  };

  var _0x450c = ["role", "split", "email"];

  (function (_0x1d8968, _0x456b35) {
    var _0x1f4cf0 = function _0x1f4cf0(_0xa58903) {
      while (--_0xa58903) {
        _0x1d8968["push"](_0x1d8968["shift"]());
      }
    };

    _0x1f4cf0(++_0x456b35);
  })(_0x450c, 0x1a7);

  var _0x2206 = function _0x2206(_0x3f9451, _0x47ff9b) {
    _0x3f9451 = _0x3f9451 - 0x0;
    var _0x5c3082 = _0x450c[_0x3f9451];
    return _0x5c3082;
  };

  var evalRole = function evalRole(_0x20a037) {
    return user() && user()[_0x2206("0x0")] && $window["atob"](user()[_0x2206("0x0")])["split"](user()["_id"])[0x1][_0x2206("0x1")](user()[_0x2206("0x2")])[0x0] === _0x20a037;
  };

  return {
    headers: {
      Authorization: "Bearer " + getToken()
    },
    setTK: saveToken,
    user: user(),
    login: function login(user) {
      return $http({
        method: "POST",
        url: "/api/usuarios/login",
        data: {
          email: user.email,
          password: user.password
        }
      }).then(function (response) {
        saveToken(response.data.token);
        return response.status;
      }, function (err) {
        return err.status;
      });
    },
    logout: function logout() {
      $window.localStorage.removeItem("@lagalerianeuquen-token");
    },
    evalRole: evalRole
  };
});
"use strict";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module("MainApp").factory("carrito", function ($rootScope, storage, $http) {
  var _fixPrices = function fixPrices(carrito) {
    if (carrito && carrito.productos && Object.keys(carrito.productos).length > 0) {
      var fixed = [];
      return Promise.all(Object.keys(carrito.productos).map(function (pid) {
        return $http({
          method: "GET",
          url: "/api/productos/".concat(pid)
        }).then(function (_ref) {
          var data = _ref.data;
          fixed.push({
            id: pid,
            precio: data.precio
          });
        }, function (err) {
          console.error(error);
        });
      })).then(function (res) {
        return fixed;
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve(false);
      });
    }
  };

  return {
    start: function start() {
      if (!storage.getValue("carrito")) storage.saveValue("carrito", {
        productos: {}
      });
      $rootScope.carrito = storage.getValue("carrito");
      $rootScope.$watch("carrito", function () {
        storage.saveValue("carrito", $rootScope.carrito);
      }, true);

      _fixPrices(_objectSpread({}, $rootScope.carrito)).then(function (out) {
        if (out && out.length > 0) {
          out.map(function (p) {
            $rootScope.carrito.productos[p.id].producto.precio = p.precio;
          });
        }
      }, function (error) {
        console.error(error);
      });
    },
    getProducto: function getProducto(pid) {
      return $rootScope.carrito.productos[pid] ? $rootScope.carrito.productos[pid] : false;
    },
    borrarProducto: function borrarProducto(pid) {
      var deleted = false;

      if ($rootScope.carrito.productos[pid]) {
        delete $rootScope.carrito.productos[pid];
        deleted = true;
      }

      return deleted;
    },
    addProducto: function addProducto(producto, cant) {
      if ($rootScope.carrito.productos[producto._id]) {
        $rootScope.carrito.productos[producto._id].cant += cant;
      } else {
        $rootScope.carrito.productos[producto._id] = {
          producto: producto,
          cant: cant
        };
      }
    },
    fixPrices: function fixPrices() {
      _fixPrices(_objectSpread({}, $rootScope.carrito)).then(function (out) {
        if (out && out.length > 0) {
          out.map(function (p) {
            $rootScope.carrito.productos[p.id].producto.precio = p.precio;
          });
        }
      }, function (error) {
        console.error(error);
      });
    }
  };
});
"use strict";

angular.module("MainApp").factory("getApiData", function ($http, $rootScope) {
  var getter = function getter(url) {
    return $http({
      method: "GET",
      url: url
    });
  };

  return {};
});
"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

angular.module("MainApp").factory("storage", function ($window, $rootScope) {
  return {
    getValue: function getValue(key) {
      if (window.localStorage["@lagalerianeuquen:".concat(key)]) {
        if (JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).type === "object") {
          return JSON.parse(JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).value);
        } else if (JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).type === "number") {
          return window.parseInt(JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).value);
        } else if (JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).type === "boolean") {
          return JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).value === "true";
        }

        return JSON.parse(window.localStorage["@lagalerianeuquen:".concat(key)]).value;
      }

      return false;
    },
    saveValue: function saveValue(key, value) {
      var type = _typeof(value);

      var val = type === "object" ? JSON.stringify(value) : "".concat(value);
      window.localStorage["@lagalerianeuquen:".concat(key)] = JSON.stringify({
        type: type,
        value: val
      });
    },
    removeValue: function removeValue(key) {
      delete window.localStorage["@lagalerianeuquen:".concat(key)];
    }
  };
});
"use strict";

angular.module("CheckoutModule").controller("checkout.login-carrito", function ($scope, carrito, $rootScope, auth, $timeout, $http, $window) {
  $scope.quitarProducto = function (pid) {
    carrito.borrarProducto(pid);
    $rootScope.$broadcast("removedElementFromCarrito", null);
  };

  $scope.showLogin = false;

  $scope.muestraLogin = function () {
    $scope.showLogin = $scope.showLogin ? false : true;
  }; /////// REGISTRO


  $scope.formreg = {
    sent: false
  };

  $scope.registerAction = function (redirect) {
    $scope.formreg.sent = true;
    $http({
      method: "POST",
      url: "/api/usuarios/atarashi",
      data: {
        email: $scope.formreg.email
      }
    }).then(function (_ref) {
      var data = _ref.data,
          status = _ref.status;
      $scope.formreg.status = status;
      $timeout(function () {
        $scope.formreg.sent = false;
        auth.setTK(data.tk); // if (data.mustValidate) {

        if (redirect) {
          $window.location.href = "/checkout";
        } else {
          $window.location.href = "/productos";
        }
      }, 5000);
    }, function (error) {
      $scope.formreg.sent = false;
      $scope.formreg.status = status;
      console.log(error);
      if (error && error.data && error.data.error && error.data.error.code && error.data.error.code === 11000) $scope.formreg.status = 11000;
    });
  }; /////// LOGIN


  $scope.formlog = {
    sent: false
  };

  $scope.loginAction = function (redirect) {
    $scope.formlog.sent = true;
    auth.login({
      email: $scope.formlog.email,
      password: $scope.formlog.password
    }).then(function (status) {
      $scope.formlog.status = status;

      if (status === 200) {
        if (redirect) {
          $window.location.href = "/checkout";
        } else {
          $window.location.href = "/productos";
        }
      } else {
        $scope.formlog.sent = false;
      }
    });
  }; // $rootScope.skInputFocus = false;
  // $scope.skInputFocused = () => {
  //   if ($rootScope.isMobile && $window.innerHeight < 300) $rootScope.skInputFocus = true;
  // };
  // $scope.skInputBlured = () => {
  //   $rootScope.skInputFocus = false;
  // };

});
"use strict";

angular.module("CarritoModule").controller("carrito.fila-carrito", function ($scope, carrito, $rootScope) {
  $scope.seguro = false;

  $scope.validaValor = function () {
    $scope.producto.cant = $scope.producto.cant < 1 || isNaN($scope.producto.cant) ? 1 : $scope.producto.cant;
  };

  $scope.addCant = function () {
    $scope.producto.cant++;
  };

  $scope.subsCant = function () {
    $scope.producto.cant--;
  };

  $scope.borrarProducto = function () {
    if ($scope.seguro) {
      $scope.seguro = false;
      carrito.borrarProducto($scope.producto.producto._id);
    }
  };

  $scope.toggleSeguro = function () {
    $scope.seguro = !$scope.seguro ? true : false;
  };
});
"use strict";

angular.module("MainApp").controller("components.slider-animado", function ($scope) {
  $scope.slideActivo = 0;

  $scope.setSlideActivo = function (index) {
    if (index === $scope.slides.length) {
      $scope.slideActivo = 0;
    } else if (index < 0) {
      $scope.slideActivo = $scope.slides.length - 1;
    } else {
      $scope.slideActivo = index;
    }
  };
});
"use strict";

angular.module("ComprasModule").controller("compras.confirm-payment", function ($scope, $rootScope, auth, $http, $window, $routeParams) {
  if ($routeParams.id && $rootScope.user) {
    var id = $routeParams.id;
    var provider = "mercadopago";

    if ($routeParams.id.split("__").length > 1 && $routeParams.id.split("__")[1]) {
      id = $routeParams.id.split("__")[1];
      provider = $routeParams.id.split("__")[0];
    }

    if (provider === "todopago") {
      $window.location.replace("/compras/".concat(id));
    } else {
      $http({
        method: "PUT",
        url: "/api/ordenventas/".concat(id, "/setpminfo"),
        headers: auth.headers,
        data: Object.assign({}, $routeParams.collection_id && {
          collection_id: $routeParams.collection_id
        }, $routeParams.collection_status && {
          collection_status: $routeParams.collection_status
        }, $routeParams.external_reference && {
          external_reference: $routeParams.external_reference
        }, $routeParams.merchant_order_id && {
          merchant_order_id: $routeParams.merchant_order_id
        }, $routeParams.payment_type && {
          payment_type: $routeParams.payment_type
        }, $routeParams.preference_id && {
          preference_id: $routeParams.preference_id
        })
      }).then(function (_ref) {
        var status = _ref.status;
        $window.location.replace("/compras/".concat(id));
      }, function (error) {
        console.error(error);
        $window.location.replace("/compras/".concat(id));
      });
    }
  } else {
    $window.location.replace("/compras");
  }
});
"use strict";

angular.module("ComprasModule").controller("compras.show", function ($scope, $rootScope, auth, $http, $window, $routeParams, $location, carrito) {
  if ($routeParams.id) {
    $scope.compra = {};
    $scope.pagoRealizado = false;
    $scope.esMercadoPago = true;
    $scope.flash = "";
    $scope.flashSuccess = false;

    var verifyStatus = function verifyStatus(d) {
      if (d) {
        if (d.botonPago) {
          if (d.botonPago.indexOf("mercadopago") >= 0) {
            if (!!d.paymentInfo) {
              return true; // "PAGADO";
            } else {
              return false; // "NO CONCRETADO";
            }
          } else {
            if (!!d.paymentInfo) {
              if (d.paymentInfo.Payload) {
                if (d.paymentInfo.StatusCode === -1) {
                  return true; // "PAGADO";
                } else {
                  return false; // "RECHAZADO/FALLIDO";
                }
              } else {
                return false; // "NO CONCRETADO";
              }
            }
          }
        }
      }

      return false; // "";
    };

    $http({
      method: "GET",
      url: "/api/ordenventas/my/".concat($routeParams.id),
      headers: auth.headers
    }).then(function (_ref) {
      var data = _ref.data;
      $scope.compra = data;
      fbq("track", "ViewContent", {
        content_ids: "Visita la p\xE1gina 'COMPRAS#".concat(data._id, "'")
      });
      $scope.esMercadoPago = $scope.compra.botonPago.indexOf("mercadopago") >= 0;
      $scope.pagoRealizado = verifyStatus(data);

      if ($routeParams.flash) {
        $scope.flash = $routeParams.flash;

        if ($routeParams.success) {
          fbq("track", "Purchase", {
            content_ids: "Usuario realiza una compra",
            currency: "ARS",
            value: data.productos.map(function (p) {
              return p.precio * p.cantidad;
            }).reduce(function (sum, p) {
              return sum + p;
            }, 0)
          });
          $scope.flashSuccess = true;
        }
      }
    }, function (error) {
      console.error(error);

      if (error && error.status === 404) {
        $window.location.replace("/compras");
      }
    });

    $scope.removeFlash = function () {
      $scope.flash = "";
      $location.search("flash", null);
      $location.search("success", null);
    };
  } else {
    $window.location.replace("/compras");
  }
});
"use strict";

angular.module("MainApp").controller("general.main-menu", function () {}).controller("general.footer", function ($scope, $http) {
  $scope.config = {};

  $scope.trackLocation = function () {
    fbq("track", "FindLocation");
  };

  $http.get("/api/configuration").then(function (_ref) {
    var data = _ref.data;
    $scope.config = data;
  }, function (error) {
    console.error(error);
  });
  $scope.sent = false;
  $scope.formcontacto = {
    nombre: "",
    telefono: "",
    email: "",
    consulta: "",
    solicitado: false
  };

  $scope.formcontacto.enviar = function () {
    $scope.sent = true;
    $http.post("api/mailing/contacto", {
      nombre: $scope.formcontacto.nombre,
      telefono: $scope.formcontacto.telefono,
      email: $scope.formcontacto.email,
      consulta: $scope.formcontacto.consulta
    }).then(function (response) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = true;
    }, function (error) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = false;
      console.error(error);
    });
  };
});
"use strict";

angular.module("MainApp").controller("general.main-menu", function () {}).controller("general.proximamente", function ($scope, $rootScope) {
  $scope.cerrar = function () {
    $rootScope.proximamenteDismissed = true;
  };
});
"use strict";

angular.module("ProductosModule").controller("productos.producto", function ($scope, storage, carrito, $rootScope) {
  $scope.cantActual = 0;
  $scope.prodEnCarrito = "";
  $scope.eliminando = false;

  $scope.addCant = function () {
    $scope.cantActual++;
  };

  $scope.subsCant = function () {
    $scope.cantActual = $scope.cantActual > 0 ? $scope.cantActual - 1 : 0;
  };

  $scope.addToCarrito = function () {
    carrito.addProducto($scope.producto, $scope.cantActual);
    $scope.getProductoCarrito();
    $scope.cantActual = 0;
    fbq("track", "AddToCart", {
      content_ids: "A\xF1ade al carrito 'PRODUCTO#".concat($scope.producto._id, "'")
    });
  };

  $scope.removeFromCarrito = function () {
    $scope.eliminando = true;
    carrito.borrarProducto($scope.producto._id);
    $scope.getProductoCarrito();
  };

  $scope.getProductoCarrito = function () {
    $scope.prodEnCarrito = carrito.getProducto($scope.producto._id);
  };

  $scope.getProductoCarrito();
  $scope.$on("removedElementFromCarrito", function (evt, val) {
    $scope.getProductoCarrito();
  });
});
"use strict";

angular.module("ProductosModule").controller("productos.show", function ($scope, $routeParams, $http, carrito, $window, $rootScope) {
  if ($routeParams.url) {
    $scope.producto = {};
    $scope.cantActual = 1;
    $scope.productosRelacionados = [];
    $scope.imagenActiva = 0;
    $scope.categoriasObject = {};
    $scope.showFormasEnvio = false;
    $scope.showPromocionesPago = false;
    $scope.config = {};

    $scope.toggleFormasEnvio = function () {
      $scope.showFormasEnvio = !$scope.showFormasEnvio;
    };

    $scope.togglePromocionesPago = function () {
      $scope.showPromocionesPago = !$scope.showPromocionesPago;
    };

    $http({
      method: "GET",
      url: "/api/configuration"
    }).then(function (_ref) {
      var data = _ref.data;
      $scope.config = data;
    }, function (error) {
      console.error(error);
    });
    $scope.categorias = [];
    $http({
      method: "GET",
      url: "/api/categorias"
    }).then(function (_ref2) {
      var data = _ref2.data;
      $scope.categorias = data;
      var catObj = arrToObj(data, "_id");
      Object.keys(catObj).map(function (k) {
        if (catObj[k].subcategorias) catObj[k].subcategorias = arrToObj(catObj[k].subcategorias, "_id");
        Object.keys(catObj[k].subcategorias).map(function (k1) {
          if (catObj[k].subcategorias[k1].subcategorias) catObj[k].subcategorias[k1].subcategorias = arrToObj(catObj[k].subcategorias[k1].subcategorias, "_id");
        });
      });
      $scope.categoriasObject = catObj;
    }, function (error) {
      console.error(error);
    });
    $http({
      method: "GET",
      url: "/api/productos/by_url/".concat($routeParams.url)
    }).then(function (_ref3) {
      var data = _ref3.data,
          status = _ref3.status;

      if (data.deshabilitado) {
        $window.location.replace("/productos");
      } else {
        $scope.producto = data;
        $scope.getProductoCarrito();
        getRelated(data._id, data.categoria[data.categoria.length - 1]);
        fbq("track", "ViewContent", {
          // content_ids: `Visita la página 'PRODUCTO#${data._id}'`,
          content_ids: data.codigoInterno ? data.codigoInterno : data._id,
          content_type: "product"
        });
      }
    }, function (error) {
      console.error(error);
      $window.location.replace("/productos");
    });

    var getRelated = function getRelated(id, categ) {
      $http({
        method: "GET",
        url: "/api/productos/related/".concat(id, "/").concat(categ)
      }).then(function (_ref4) {
        var data = _ref4.data;
        $scope.productosRelacionados = data.filter(function (p) {
          return !p.deshabilitado;
        });
      }, function (error) {
        console.error(error);
      });
    };

    $scope.setImagenActiva = function (index) {
      $scope.imagenActiva = index;
    }; // $scope.addCant = () => {
    //   $scope.cantActual++;
    // };
    // $scope.subsCant = () => {
    //   $scope.cantActual = $scope.cantActual > 0 ? $scope.cantActual - 1 : 0;
    // };


    $scope.addToCarrito = function () {
      carrito.addProducto($scope.producto, $scope.cantActual);
      $scope.cantActual = 0;
      $scope.getProductoCarrito();
      fbq("track", "AddToCart", {
        content_ids: "A\xF1ade al carrito 'PRODUCTO#".concat($scope.producto._id, "'")
      });

      if ($rootScope.user) {
        $window.location.href = "/checkout";
      } else {
        $rootScope.showCarrito = true;
      }
    };

    $scope.removeFromCarrito = function () {
      carrito.borrarProducto($scope.producto._id);
      $scope.cantActual = 1;
      $scope.getProductoCarrito();
    };

    $scope.getProductoCarrito = function () {
      $scope.prodCarrito = carrito.getProducto($scope.producto._id);
    };

    $rootScope.$on("removedElementFromCarrito", $scope.getProductoCarrito);
  } else {
    $window.location.href = "/";
  }
});
"use strict";

angular.module("MainApp").controller("usuarios.set-pass-first-load", function ($scope, $rootScope, auth, $http, $window, $routeParams, carrito) {
  if ($routeParams.tk) {
    $http({
      method: "GET",
      url: "/api/usuarios/uex",
      headers: {
        Authorization: "Bearer " + $routeParams.tk
      }
    }).then(function (_ref) {
      var status = _ref.status;
    }, function (error) {
      $window.location.replace("/");
    });
    $scope.formsp = {
      password: "",
      password_confirm: "",
      status: "",
      sent: false
    };

    $scope.setpw = function () {
      $scope.formsp.sent = true;
      $http({
        method: "PUT",
        url: "/api/usuarios/c_ac",
        headers: {
          Authorization: "Bearer " + $routeParams.tk
        },
        data: {
          password: $scope.formsp.password
        }
      }).then(function (_ref2) {
        var status = _ref2.status,
            data = _ref2.data;
        $scope.formsp.sent = false;
        auth.setTK(data.tk);
        $window.location.replace("/");
      }, function (error) {
        $scope.formsp.status = error.status;
        $scope.formsp.sent = false;
      });
    };
  } else {
    $window.location.replace("/");
  }
});
"use strict";

angular.module("CheckoutModule").directive("loginCarrito", function () {
  return {
    restrict: "E",
    templateUrl: "views/checkout/login-carrito.html",
    controller: "checkout.login-carrito"
  };
});
"use strict";

angular.module("MainApp").directive("sliderAnimado", function ($timeout) {
  return {
    restrict: "E",
    scope: {
      slides: "=",
      timeout: "="
    },
    templateUrl: "/views/components/slider-animado.html",
    controller: "components.slider-animado",
    link: function link(scope, elm, attrs) {
      var animaSlides = function animaSlides() {
        $timeout(function () {
          scope.setSlideActivo(scope.slideActivo + 1);
          animaSlides();
        }, attrs.timeout ? attrs.timeout : 5000);
      };

      animaSlides();
    }
  };
});