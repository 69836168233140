angular
  .module("MainApp", [
    "ngRoute",
    "ngTouch",
    "HomeModule",
    "ProductosModule",
    // "CarritoModule"
    "CheckoutModule",
    "ComprasModule",
    "angular-google-analytics"
  ])
  .config(AnalyticsProvider => {
    AnalyticsProvider.setAccount("UA-147115981-1");
  })
  .controller(
    "mainController",
    ($location, $rootScope, $window, carrito, auth, $http, Analytics) => {
      $rootScope.user = auth.user;
      $rootScope.skInputFocus = false;

      carrito.start();

      $rootScope.isMobile = $window.innerWidth <= 768;

      const originalWindowHeight = $window.innerHeight;

      angular.element($window).bind("resize", () => {
        $rootScope.isMobile = $window.innerWidth <= 768;
        if ($window.innerHeight > 350) {
          $rootScope.skInputFocus = false;
        } else {
          $rootScope.skInputFocus = true;
        }
        $rootScope.$$phase || $rootScope.$apply();
      });

      $rootScope.$on("$routeChangeSuccess", () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        let bodyClass = "";
        Analytics.trackPage($location.$$path);
        let path = $location.$$path.split("/");

        path.map(tramo => {
          if (tramo !== "") {
            bodyClass += `-${tramo}`;
          }
        });

        $rootScope.bodyClass =
          bodyClass !== "" ? `page${bodyClass}` : "page-home";

        $rootScope.menuOpened = false;

        $rootScope.toggleMenu = () => {
          $rootScope.menuOpened = $rootScope.menuOpened ? false : true;
          $rootScope.showCarrito = false;
        };

        $rootScope.closeMenu = () => {
          $rootScope.menuOpened = false;
        };
      });

      $rootScope.proximamenteDismissed = false;

      $rootScope.config = {};

      $http.get("/api/configuration").then(
        ({ data }) => {
          $rootScope.config = data;
        },
        error => {
          console.error(error);
        }
      );

      $rootScope.showCarrito = false;

      $rootScope.toggleCarrito = val => {
        $rootScope.showCarrito = val;
        $rootScope.menuOpened = false;
        $rootScope.$$phase || $rootScope.$apply();
      };
    }
  );
