angular
  .module("MainApp")
  .controller(
    "usuarios.set-pass-first-load",
    ($scope, $rootScope, auth, $http, $window, $routeParams, carrito) => {
      if ($routeParams.tk) {
        $http({
          method: "GET",
          url: `/api/usuarios/uex`,
          headers: {
            Authorization: "Bearer " + $routeParams.tk
          }
        }).then(
          ({ status }) => {},
          error => {
            $window.location.replace("/");
          }
        );

        $scope.formsp = {
          password: "",
          password_confirm: "",
          status: "",
          sent: false
        };

        $scope.setpw = () => {
          $scope.formsp.sent = true;
          $http({
            method: "PUT",
            url: `/api/usuarios/c_ac`,
            headers: {
              Authorization: "Bearer " + $routeParams.tk
            },
            data: {
              password: $scope.formsp.password
            }
          }).then(
            ({ status, data }) => {
              $scope.formsp.sent = false;
              auth.setTK(data.tk);
              $window.location.replace("/");
            },
            error => {
              $scope.formsp.status = error.status;
              $scope.formsp.sent = false;
            }
          );
        };
      } else {
        $window.location.replace("/");
      }
    }
  );
