angular.module("MainApp").config(($routeProvider, $locationProvider) => {
  $routeProvider
    .when("/productos", {
      templateUrl: "/views/productos/index.html",
      controller: "productos.index",
      reloadOnSearch: false
    })
    .when("/productos/:url", {
      templateUrl: "/views/productos/show.html",
      controller: "productos.show"
    })
    .when("/checkout", {
      templateUrl: "views/checkout/index.html",
      controller: "checkout.index",
      resolve: {
        secureAccess: ($rootScope, $window) => {
          if (!$rootScope.user) {
            $window.location.replace("/");
          }
        }
      }
    })
    .when("/compras", {
      templateUrl: "/views/compras/index.html",
      controller: "compras.index",
      resolve: {
        secureAccess: ($rootScope, $window) => {
          if (!$rootScope.user) {
            $window.location.replace("/");
          }
        }
      }
    })
    .when("/compras/confirm-payment/:id", {
      templateUrl: "/views/compras/confirm-payment.html",
      controller: "compras.confirm-payment",
      resolve: {
        secureAccess: ($rootScope, $window) => {
          if (!$rootScope.user) {
            $window.location.replace("/");
          }
        }
      }
    })
    .when("/compras/:id", {
      templateUrl: "/views/compras/show.html",
      controller: "compras.show",
      resolve: {
        secureAccess: ($rootScope, $window) => {
          if (!$rootScope.user) {
            $window.location.replace("/");
          }
        }
      }
    });
});
