angular.module("MainApp").directive("sliderAnimado", $timeout => {
  return {
    restrict: "E",
    scope: {
      slides: "=",
      timeout: "="
    },
    templateUrl: "/views/components/slider-animado.html",
    controller: "components.slider-animado",
    link: (scope, elm, attrs) => {
      
      const animaSlides = () => {
        $timeout(() => {
          scope.setSlideActivo(scope.slideActivo + 1);
          animaSlides();
        }, attrs.timeout ? attrs.timeout : 5000);
      };
      animaSlides();
    }
  };
});
