angular.module("MainApp").factory("auth", ($http, $window) => {
  const getToken = () => $window.localStorage["@lagalerianeuquen-token"];
  const saveToken = token => {
    $window.localStorage["@lagalerianeuquen-token"] = token;
  };
  const user = () => {
    if (
      getToken() &&
      JSON.parse($window.atob(getToken().split(".")[1])).exp > Date.now() / 1000
    ) {
      let payload = JSON.parse($window.atob(getToken().split(".")[1]));
      return Object.assign(
        {},
        {
          _id: payload._id,
          email: payload.email,
          name: payload.name
        },
        payload.role && { role: payload.role }
      );
    } else {
      return false;
    }
  };

  var _0x450c = ["role", "split", "email"];
  (function(_0x1d8968, _0x456b35) {
    var _0x1f4cf0 = function(_0xa58903) {
      while (--_0xa58903) {
        _0x1d8968["push"](_0x1d8968["shift"]());
      }
    };
    _0x1f4cf0(++_0x456b35);
  })(_0x450c, 0x1a7);
  var _0x2206 = function(_0x3f9451, _0x47ff9b) {
    _0x3f9451 = _0x3f9451 - 0x0;
    var _0x5c3082 = _0x450c[_0x3f9451];
    return _0x5c3082;
  };
  const evalRole = _0x20a037 =>
    user() &&
    user()[_0x2206("0x0")] &&
    $window["atob"](user()[_0x2206("0x0")])
      ["split"](user()["_id"])[0x1]
      [_0x2206("0x1")](user()[_0x2206("0x2")])[0x0] === _0x20a037;

  return {
    headers: {
      Authorization: "Bearer " + getToken()
    },
    setTK: saveToken,
    user: user(),
    login: user =>
      $http({
        method: "POST",
        url: "/api/usuarios/login",
        data: {
          email: user.email,
          password: user.password
        }
      }).then(
        response => {
          saveToken(response.data.token);
          return response.status;
        },
        err => {
          return err.status;
        }
      ),
    logout: () => {
      $window.localStorage.removeItem("@lagalerianeuquen-token");
    },
    evalRole: evalRole
  };
});
