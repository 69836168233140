angular
  .module("ComprasModule")
  .controller(
    "compras.show",
    (
      $scope,
      $rootScope,
      auth,
      $http,
      $window,
      $routeParams,
      $location,
      carrito
    ) => {
      if ($routeParams.id) {
        $scope.compra = {};
        $scope.pagoRealizado = false;
        $scope.esMercadoPago = true;
        $scope.flash = "";
        $scope.flashSuccess = false;

        const verifyStatus = (d) => {
          if (d) {
            if (d.botonPago) {
              if (d.botonPago.indexOf("mercadopago") >= 0) {
                if (!!d.paymentInfo) {
                  return true; // "PAGADO";
                } else {
                  return false; // "NO CONCRETADO";
                }
              } else {
                if (!!d.paymentInfo) {
                  if (d.paymentInfo.Payload) {
                    if (d.paymentInfo.StatusCode === -1) {
                      return true; // "PAGADO";
                    } else {
                      return false; // "RECHAZADO/FALLIDO";
                    }
                  } else {
                    return false; // "NO CONCRETADO";
                  }
                }
              }
            }
          }
          return false; // "";
        };

        $http({
          method: "GET",
          url: `/api/ordenventas/my/${$routeParams.id}`,
          headers: auth.headers,
        }).then(
          ({ data }) => {
            $scope.compra = data;
            fbq("track", "ViewContent", {
              content_ids: `Visita la página 'COMPRAS#${data._id}'`,
            });

            $scope.esMercadoPago =
              $scope.compra.botonPago.indexOf("mercadopago") >= 0;
            $scope.pagoRealizado = verifyStatus(data);

            if ($routeParams.flash) {
              $scope.flash = $routeParams.flash;
              if ($routeParams.success) {
                fbq("track", "Purchase", {
                  content_ids: `Usuario realiza una compra`,
                  currency: "ARS",
                  value: data.productos
                    .map((p) => p.precio * p.cantidad)
                    .reduce((sum, p) => sum + p, 0),
                });

                $scope.flashSuccess = true;
              }
            }
          },
          (error) => {
            console.error(error);
            if (error && error.status === 404) {
              $window.location.replace("/compras");
            }
          }
        );

        $scope.removeFlash = () => {
          $scope.flash = "";
          $location.search("flash", null);
          $location.search("success", null);
        };
      } else {
        $window.location.replace("/compras");
      }
    }
  );
