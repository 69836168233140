angular
  .module("MainApp")
  .filter("contarProductos", () => {
    return productos =>
      productos
        ? Object.keys(productos).reduce((acumulador, pid) => {
            acumulador += productos[pid].cant;
            return acumulador;
          }, 0)
        : "";
  })
  .filter("precio", () => {
    return input => {
      if (input) {
        return parseFloat(input).toFixed(2);
      }
      return "";
    };
  })
  .filter("encodeurl", () => {
    return input => {
      if (input) {
        return window.encodeURIComponent(input);
      }
      return "";
    };
  })
  .filter("removeTrailingSlash", () => {
    return input => {
      if (input) {
        if (input[0] === "/") return input.slice(1);
        else return input;
      }
      return "";
    };
  })
  .filter("objLength", () => obj => (obj ? Object.keys(obj).length : ""))
  .filter("urlifyProducto", () => producto =>
    producto && producto.nombre && producto._id
      ? `${producto.nombre
          .toLowerCase()
          .replace(/\//g, "%2F")
          .replace(/\s/g, "-")
          .replace(/á/g, "a")
          .replace(/é/g, "e")
          .replace(/í/g, "i")
          .replace(/ó/g, "o")
          .replace(/ú/g, "u")
          .replace(/ñ/g, "n")}-${producto._id}`
      : ""
  )
  .filter("precioTotalCarrito", () => {
    return productos =>
      productos
        ? Object.keys(productos).reduce((acumulador, pid) => {
            if (
              acumulador >= 0 &&
              productos[pid].producto.precio &&
              productos[pid].producto.precio > 0
            ) {
              acumulador +=
                (productos[pid].cant
                  ? productos[pid].cant
                  : productos[pid].cantidad
                  ? productos[pid].cantidad
                  : 0) *
                (productos[pid].producto.descuento
                  ? productos[pid].producto.precio -
                    productos[pid].producto.precio *
                      (productos[pid].producto.descuento / 100)
                  : productos[pid].producto.precio);
            } else {
              acumulador = -1;
            }
            return acumulador;
          }, 0)
        : "";
  })
  .filter("precioTotal", () => {
    return productos =>
      productos
        ? Object.keys(productos).reduce((acumulador, pid) => {
            if (
              acumulador >= 0 &&
              productos[pid].precio &&
              productos[pid].precio > 0
            ) {
              acumulador +=
                (productos[pid].cant
                  ? productos[pid].cant
                  : productos[pid].cantidad
                  ? productos[pid].cantidad
                  : 0) * productos[pid].precio;
            } else {
              acumulador = -1;
            }
            return acumulador;
          }, 0)
        : "";
  })
  .filter("padTwo", () => number =>
    number <= 9 ? ("0" + number).slice(-2) : number
  );
