angular
  .module("MainApp")
  .directive("homeCabecera", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-cabecera.html"
    };
  })
  .directive("homeCategorias", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-categorias.html"
    };
  })
  .directive("homeDestacados", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-destacados.html"
    };
  })
  .directive("homePromo", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-promo.html"
    };
  });
