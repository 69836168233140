angular
  .module("CarritoModule")
  .controller("carrito.fila-carrito", ($scope, carrito, $rootScope) => {
    $scope.seguro = false;

    $scope.validaValor = () => {
      $scope.producto.cant =
        $scope.producto.cant < 1 || isNaN($scope.producto.cant)
          ? 1
          : $scope.producto.cant;
    };

    $scope.addCant = () => {
      $scope.producto.cant++;
    };

    $scope.subsCant = () => {
      $scope.producto.cant--;
    };

    $scope.borrarProducto = () => {
      if ($scope.seguro) {
        $scope.seguro = false;
        carrito.borrarProducto($scope.producto.producto._id);
      }
    };

    $scope.toggleSeguro = () => {
      $scope.seguro = !$scope.seguro ? true : false;
    };
  });
