angular.module("MainApp").factory("carrito", ($rootScope, storage, $http) => {
  const fixPrices = carrito => {
    if (
      carrito &&
      carrito.productos &&
      Object.keys(carrito.productos).length > 0
    ) {
      const fixed = [];

      return Promise.all(
        Object.keys(carrito.productos).map(pid =>
          $http({
            method: "GET",
            url: `/api/productos/${pid}`
          }).then(
            ({ data }) => {
              fixed.push({
                id: pid,
                precio: data.precio
              });
            },
            err => {
              console.error(error);
            }
          )
        )
      ).then(res => {
        return fixed;
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
  };

  return {
    start: () => {
      if (!storage.getValue("carrito"))
        storage.saveValue("carrito", { productos: {} });
      $rootScope.carrito = storage.getValue("carrito");

      $rootScope.$watch(
        "carrito",
        () => {
          storage.saveValue("carrito", $rootScope.carrito);
        },
        true
      );

      fixPrices({ ...$rootScope.carrito }).then(
        out => {
          if (out && out.length > 0) {
            out.map(p => {
              $rootScope.carrito.productos[p.id].producto.precio = p.precio;
            });
          }
        },
        error => {
          console.error(error);
        }
      );
    },

    getProducto: pid =>
      $rootScope.carrito.productos[pid]
        ? $rootScope.carrito.productos[pid]
        : false,

    borrarProducto: pid => {
      let deleted = false;
      if ($rootScope.carrito.productos[pid]) {
        delete $rootScope.carrito.productos[pid];
        deleted = true;
      }
      return deleted;
    },

    addProducto: (producto, cant) => {
      if ($rootScope.carrito.productos[producto._id]) {
        $rootScope.carrito.productos[producto._id].cant += cant;
      } else {
        $rootScope.carrito.productos[producto._id] = {
          producto: producto,
          cant: cant
        };
      }
    },

    fixPrices: () => {
      fixPrices({ ...$rootScope.carrito }).then(
        out => {
          if (out && out.length > 0) {
            out.map(p => {
              $rootScope.carrito.productos[p.id].producto.precio = p.precio;
            });
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  };
});
