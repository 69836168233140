angular
  .module("ProductosModule")
  .controller("productos.producto", ($scope, storage, carrito, $rootScope) => {
    $scope.cantActual = 0;
    $scope.prodEnCarrito = "";
    $scope.eliminando = false;

    $scope.addCant = () => {
      $scope.cantActual++;
    };
    $scope.subsCant = () => {
      $scope.cantActual = $scope.cantActual > 0 ? $scope.cantActual - 1 : 0;
    };

    $scope.addToCarrito = () => {
      carrito.addProducto($scope.producto, $scope.cantActual);
      $scope.getProductoCarrito();
      $scope.cantActual = 0;
      fbq("track", "AddToCart", {
        content_ids: `Añade al carrito 'PRODUCTO#${$scope.producto._id}'`
      });
    };
    $scope.removeFromCarrito = () => {
      $scope.eliminando = true;
      carrito.borrarProducto($scope.producto._id);
      $scope.getProductoCarrito();
    };

    $scope.getProductoCarrito = () => {
      $scope.prodEnCarrito = carrito.getProducto($scope.producto._id);
    };
    $scope.getProductoCarrito();

    $scope.$on("removedElementFromCarrito", (evt, val) => {
      $scope.getProductoCarrito();
    });
  });
