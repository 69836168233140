angular
  .module("CarritoModule", [])
  .controller("carrito.index", ($scope, $rootScope, $http, $filter) => {
    $scope.showPresupuesto = false;

    $scope.cantTotal = 0;

    $scope.muestraFormPresupuesto = () => {
      $scope.showPresupuesto = true;
    };
    $scope.escondeFormPresupuesto = () => {
      $scope.showPresupuesto = false;
    };

    $scope.solic = {
      nombre: "",
      empresa: "",
      email: ""
    };

    $scope.presupuesto = {
      estado: 0,
      enviado: false,
      mensaje: ""
    };

    $scope.sent = false;

    $scope.presupuestar = (payerInfo, soloCliente = false) => {
      $scope.sent = true;
      if (Object.keys($scope.carrito.productos).length > 0) {
        $http
          .post("/api/mailing/presupuesto", {
            solic: $scope.solic,
            productos: $scope.carrito.productos,
            total: $scope.total,
            soloCliente: soloCliente
          })
          .then(
            response => {
              if (response.status === 200) {
                $scope.presupuesto.estado = response.status;
                $scope.presupuesto.mensaje =
                  "Presupuesto enviado correctamente";
                $scope.presupuesto.enviado = true;
              }
              $scope.sent = false;
            },
            error => {
              $scope.sent = false;
              $scope.presupuesto.estado = 200;
              $scope.presupuesto.mensaje =
                "Ocurrió un problema al enviar el presupuesto, por favor, verifica tus datos e inténtalo nuevamente";
              $scope.presupuesto.enviado = true;
              console.error(error);
            }
          );
      }
    };

    $scope.imprimePresupuesto = () => {
      let script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.37/pdfmake.min.js";
      document.body.appendChild(script);
      script.onload = () => {
        let script2 = document.createElement("script");
        script2.src =
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.37/vfs_fonts.js";
        document.body.appendChild(script2);
        script2.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = 2226;
          canvas.height = 406;
          let context = canvas.getContext("2d");

          let base_image = new Image();

          base_image.onload = () => {
            context.drawImage(base_image, 0, 0);

            let outPDF = {
              content: [
                {
                  image: "logo",
                  width: 515
                },
                {
                  text: "\n\n"
                },
                {
                  table: {
                    margin: 30,
                    headerRows: 1,
                    widths: ["*", "*", "*"],

                    body: [["Producto", "Precio x Cantidad", "Precio Total"]]
                  }
                },
                {
                  text: "\n\n"
                },
                {
                  text:
                    "Los presupuestos son enviados a ventas@lagalerianeuquen.com"
                }
              ],
              images: {
                logo: canvas.toDataURL("image/jpeg")
              }
            };

            for (let [pid, producto] of Object.entries(
              $rootScope.carrito.productos
            )) {
              if (producto.cant > 0) {
                outPDF.content[2].table.body.push([
                  {
                    text: producto.producto.nombre,
                    bold: true
                  },
                  {
                    text: `$ ${producto.producto.precio} x ${
                      producto.cant
                    } unidades`
                  },
                  {
                    text: `$ ${producto.producto.precio * producto.cant}`
                  }
                ]);
              }
            }

            outPDF.content[2].table.body.push([
              "",
              "",
              {
                text: `Total: $ ${$filter("precioTotal")(
                  $rootScope.carrito.productos
                ).toFixed(2)}`,
                bold: true,
                fontSize: 13
              }
            ]);

            pdfMake
              .createPdf(outPDF)
              .download(
                `Presupuesto lagalerianeuquen ${new Date().getDate() +
                  "-" +
                  new Date().getMonth()}.pdf`
              );
          };

          base_image.src = "/assets/images/logo-pdf.jpg";
        };
      };
    };
  })
  .filter("cantidadProductos", () => productos =>
    productos && Object.keys(productos).length > 0
      ? Object.keys(productos).reduce((acumulador, pid) => {
          acumulador += productos[pid].cant;
          return acumulador;
        }, 0)
      : 0
  )
  .filter("precioTotal", () => productos =>
    productos && Object.keys(productos).length > 0
      ? Object.keys(productos).reduce((acumulador, pid) => {
          acumulador += productos[pid].cant * productos[pid].producto.precio;
          return acumulador;
        }, 0)
      : 0
  );
