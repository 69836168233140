angular
  .module("ProductosModule")
  .directive("listaCardProductos", () => {
    return {
      restrict: "E",
      scope: {
        productos: "=",
        offsetProductos: "=",
        cantProductos: "="
      },
      templateUrl: "/views/productos/lista-cards.html"
    };
  })
  .directive("cardProducto", () => {
    return {
      restrict: "E",
      scope: {
        producto: "="
      },
      controller: "productos.producto",
      templateUrl: "/views/productos/card-producto.html"
    };
  })
  .directive("expandeLista", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(this)
            .find("ul") // [TBM]
            .fadeIn(parseInt(attrs.time));
        });
      }
    };
  })
  .directive("cierraTodos", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(".lista-categorias").hide(); // [TMB]
        });
      }
    };
  })
  .directive("calcularAltura", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        let height = 0;
        height += elm.find("> .subcategoria-n1").length * 20;
        height +=
          elm.find("> .contenido-subcategoria-n1 >.subcategoria-n2").length *
          32;
        // scope.alturacalculada=`{height:'${height}px'}`;
        elm.height(`${height}px`);
      }
    };
  })
  .directive("reachingEnd", $timeout => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $timeout(() => {
          if (
            $(window).scrollTop() + window.innerHeight >=
            $("footer-section").offset().top - 0
          ) {
            $(elm).addClass("attached");
          } else {
            $(elm).removeClass("attached");
          }
        });
        $(window).on("scroll", e => {
          if (
            $(window).scrollTop() + window.innerHeight >=
            $("footer-section").offset().top - 0
          ) {
            $(elm).addClass("attached");
          } else {
            $(elm).removeClass("attached");
          }
        });
      }
    };
  });
