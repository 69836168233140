angular
  .module("ComprasModule", [])
  .controller(
    "compras.index",
    ($scope, auth, $http, $routeParams, $location) => {
      fbq("track", "ViewContent", {
        content_ids: `Visita la página 'COMPRAS#INDEX'`,
      });

      $scope.compras = [];
      $scope.flash = "";

      const verifyStatus = (d) => {
        if (d) {
          if (d.botonPago) {
            if (d.botonPago.indexOf("mercadopago") >= 0) {
              if (!!d.paymentInfo) {
                return true; // "PAGADO";
              } else {
                return false; // "NO CONCRETADO";
              }
            } else {
              if (!!d.paymentInfo) {
                if (d.paymentInfo.Payload) {
                  if (d.paymentInfo.StatusCode === -1) {
                    return true; // "PAGADO";
                  } else {
                    return false; // "RECHAZADO/FALLIDO";
                  }
                } else {
                  return false; // "NO CONCRETADO";
                }
              }
            }
          }
        }
        return false; // "";
      };

      $http({
        method: "GET",
        url: "/api/ordenventas/my",
        headers: auth.headers,
      }).then(
        ({ data }) => {
          $scope.compras = data.map((c) => ({
            ...c,
            pagoRealizado: verifyStatus(c),
          }));
          console.log($scope.compras);
        },
        (error) => {
          console.error(error);
        }
      );

      if ($routeParams.flash) {
        $scope.flash = $routeParams.flash;
      }
      $scope.removeFlash = () => {
        $scope.flash = "";
        $location.search("flash", null);
      };
    }
  );
