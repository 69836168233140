angular.module("CarritoModule").directive("filaCarrito", () => {
  return {
    restrict: "A",
    scope: {
      producto: "="
    },
    controller: "carrito.fila-carrito",
    templateUrl: "views/carrito/fila-carrito.html"
  };
});
