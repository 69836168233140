angular
  .module("CheckoutModule")
  .controller(
    "checkout.login-carrito",
    ($scope, carrito, $rootScope, auth, $timeout, $http, $window) => {

      $scope.quitarProducto = pid => {
        carrito.borrarProducto(pid);
        $rootScope.$broadcast("removedElementFromCarrito", null);
      };
      $scope.showLogin = false;
      $scope.muestraLogin = () => {
        $scope.showLogin = $scope.showLogin ? false : true;
      };
      /////// REGISTRO
      $scope.formreg = {
        sent: false
      };
      $scope.registerAction = redirect => {
        $scope.formreg.sent = true;
        $http({
          method: "POST",
          url: "/api/usuarios/atarashi",
          data: {
            email: $scope.formreg.email
          }
        }).then(
          ({ data, status }) => {
            $scope.formreg.status = status;
            $timeout(() => {
              $scope.formreg.sent = false;
              auth.setTK(data.tk);
              // if (data.mustValidate) {
              if (redirect) {
                $window.location.href = "/checkout";
              } else {
                $window.location.href = "/productos";
              }
            }, 5000);
          },
          error => {
            $scope.formreg.sent = false;
            $scope.formreg.status = status;
            console.log(error);
            if (
              error &&
              error.data &&
              error.data.error &&
              error.data.error.code &&
              error.data.error.code === 11000
            )
              $scope.formreg.status = 11000;
          }
        );
      };
      /////// LOGIN
      $scope.formlog = {
        sent: false
      };
      $scope.loginAction = redirect => {
        $scope.formlog.sent = true;
        auth
          .login({
            email: $scope.formlog.email,
            password: $scope.formlog.password
          })
          .then(status => {
            $scope.formlog.status = status;
            if (status === 200) {
              if (redirect) {
                $window.location.href = "/checkout";
              } else {
                $window.location.href = "/productos";
              }
            } else {
              $scope.formlog.sent = false;
            }
          });
      };

      // $rootScope.skInputFocus = false;
      // $scope.skInputFocused = () => {
      //   if ($rootScope.isMobile && $window.innerHeight < 300) $rootScope.skInputFocus = true;
      // };
      // $scope.skInputBlured = () => {
      //   $rootScope.skInputFocus = false;
      // };
    }
  );
