angular
  .module("MainApp")
  .directive("mainMenu", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/main-menu.html",
      controller: "general.main-menu"
    };
  })
  .directive("footerSection", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/footer-section.html",
      controller: "general.footer"
    };
  })
  .directive("proximamente", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/proximamente.html",
      controller: "general.proximamente"
    };
  })
  .directive("mantenimiento", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/mantenimiento.html"
    };
  })
  .directive("tarjetaProducto", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/tarjeta-producto.html",
      scope: {
        producto: "=",
        config: "="
      }
    };
  })
  .directive("loadFrame", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $("document").ready(() => {
          $(elm).attr("src", attrs.source);
        });
      }
    };
  })
  .directive("scrollRight", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(attrs.target).animate(
            {
              scrollLeft: "+=" + $(attrs.target).width()
            },
            parseInt(attrs.time)
          );
        });
      }
    };
  })
  .directive("scrollLeft", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(attrs.target).animate(
            {
              scrollLeft: "-=" + $(attrs.target).width()
            },
            parseInt(attrs.time)
          );
        });
      }
    };
  })
  .directive("scrollToContacto", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("body, html").animate(
            {
              scrollTop: $("footer-section").offset().top
            },
            500
          );
        });
      }
    };
  })
  .directive("scrollToTarget", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("body, html").animate(
            {
              scrollTop: $(attrs.target).offset().top
            },
            700
          );
        });
      }
    };
  })
  .directive("escondeMenu", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("#navbar").collapse("hide");
        });
      }
    };
  })
  .directive("flotante", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(window).on("scroll", e => {
          if ($(window).scrollTop() > 67) {
            $(elm).addClass("flotante");
          } else {
            $(elm).removeClass("flotante");
          }
        });
      }
    };
  })
  .directive("colorSvg", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        elm[0].addEventListener("load", function() {
          let paths = this.getSVGDocument().querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            paths[i].setAttribute("style", `fill: ${attrs.colorSvg}`);
          }
        });
      }
    };
  })
  .directive("scrollTargetDown", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        let mouseOver = false;
        elm[0].addEventListener("mouseenter", () => {
          mouseOver = true;
          scrollTarget();
        });
        elm[0].addEventListener("mouseleave", () => {
          mouseOver = false;
          stopScroll();
        });
        const stopScroll = () => {
          $(attrs.scrollTargetDown).stop();
        };
        const scrollTarget = () => {
          if (mouseOver) {
            $(attrs.scrollTargetDown)
              .stop()
              .animate(
                {
                  scrollTop: "+=50px"
                },
                400,
                "linear",
                () => {
                  scrollTarget();
                }
              );
          }
        };
      }
    };
  })
  .directive("scrollTargetUp", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        let mouseOver = false;
        elm[0].addEventListener("mouseenter", () => {
          mouseOver = true;
          scrollTarget();
        });
        elm[0].addEventListener("mouseleave", () => {
          mouseOver = false;
          stopScroll();
        });
        const stopScroll = () => {
          $(attrs.scrollTargetUp).stop();
        };
        const scrollTarget = () => {
          if (mouseOver) {
            $(attrs.scrollTargetUp)
              .stop()
              .animate(
                {
                  scrollTop: "-=50px"
                },
                400,
                "linear",
                () => {
                  scrollTarget();
                }
              );
          }
        };
      }
    };
  });
