angular
  .module("HomeModule", [])
  .controller("home.index", ($scope, $http, $timeout) => {
    fbq("track", "ViewContent", {
      content_ids: `Visita la página 'HOME'`
    });

    $scope.slides = [];

    $scope.error = false;

    $http.get("/api/sliders").then(
      ({ data }) => {
        $scope.slides = data;
      },
      error => {
        console.error(error);
        $scope.error =
          "Error al cargar los slides, por favor recargue la página";
      }
    );

    $scope.productosDestacados = [];

    $http({
      method: "GET",
      url: "/api/productos/destacados"
    }).then(
      ({ data }) => {
        $scope.productosDestacados = data;
      },
      error => {
        console.error(error);
      }
    );

    $scope.categoriaSelected = "";
    $scope.categorias = [];

    $http({
      method: "GET",
      url: "/api/categorias"
    }).then(
      ({ data }) => {
        $scope.categorias = data;
        $scope.categoriaSelected = data[0]._id;
      },
      error => {
        console.error(error);
      }
    );

    $scope.setCategoriaSelected = id => {
      $scope.categoriaSelected = id;
    };

    $scope.promocion = {};

    $http({ method: "GET", url: "/api/promocions/next" }).then(
      ({ data }) => {
        $scope.promocion = data;
        countDown();
      },
      error => {
        console.error(error);
      }
    );

    const countDown = () => {
      const resta = new Date($scope.promocion.fechaFin) - new Date();
      if (resta > 0) {
        $scope.restaDias = Math.floor(resta / (1000 * 60 * 60 * 24));
        $scope.restaHoras = Math.floor(
          (resta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        $scope.restaMinutos = Math.floor(
          (resta % (1000 * 60 * 60)) / (1000 * 60)
        );
        $scope.restaSegundos = Math.floor((resta % (1000 * 60)) / 1000);
        $timeout(() => {
          countDown();
        }, 1000);
      } else {
        $scope.promocionVencida = true;
      }
    };
  });
