angular
  .module("ComprasModule")
  .controller(
    "compras.confirm-payment",
    ($scope, $rootScope, auth, $http, $window, $routeParams) => {
      if ($routeParams.id && $rootScope.user) {
        let id = $routeParams.id;
        let provider = "mercadopago";
        if (
          $routeParams.id.split("__").length > 1 &&
          $routeParams.id.split("__")[1]
        ) {
          id = $routeParams.id.split("__")[1];
          provider = $routeParams.id.split("__")[0];
        }
        if (provider === "todopago") {
          $window.location.replace(`/compras/${id}`);
        } else {
          $http({
            method: "PUT",
            url: `/api/ordenventas/${id}/setpminfo`,
            headers: auth.headers,
            data: Object.assign(
              {},
              $routeParams.collection_id && {
                collection_id: $routeParams.collection_id,
              },
              $routeParams.collection_status && {
                collection_status: $routeParams.collection_status,
              },
              $routeParams.external_reference && {
                external_reference: $routeParams.external_reference,
              },
              $routeParams.merchant_order_id && {
                merchant_order_id: $routeParams.merchant_order_id,
              },
              $routeParams.payment_type && {
                payment_type: $routeParams.payment_type,
              },
              $routeParams.preference_id && {
                preference_id: $routeParams.preference_id,
              }
            ),
          }).then(
            ({ status }) => {
              $window.location.replace(`/compras/${id}`);
            },
            (error) => {
              console.error(error);
              $window.location.replace(`/compras/${id}`);
            }
          );
        }
      } else {
        $window.location.replace("/compras");
      }
    }
  );
