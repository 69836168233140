angular
  .module("MainApp")
  .config(($httpProvider, $routeProvider, $locationProvider) => {
    $httpProvider.defaults.headers.common["X-Requested-With"] =
      "XMLHttpRequest";

    $locationProvider.html5Mode(true);

    $routeProvider
      .when("/", {
        templateUrl: "/views/home/index.html",
        controller: "home.index"
      })
      .when("/nosotros", {
        templateUrl: "/views/nosotros/index.html",
        controller: () => {
          fbq("track", "ViewContent", {
            content_ids: `Visita la página 'NOSOTROS'`
          });
        }
      })

      ///////////

      .when("/spfl", {
        templateUrl: "/views/usuarios/set-pass-first-load.html",
        controller: "usuarios.set-pass-first-load"
      })

      .when("/logout", {
        template: "",
        controller: (auth, $window) => {
          auth.logout();
          $window.location.replace("/");
        }
      })

      ///////////

      .otherwise({
        templateUrl: "/views/home/index.html",
        redirectTo: "/"
      });
  });
